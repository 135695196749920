import React from "react";

export default function CartComponent(props) {
	const { product, addToCart, removeFromCart } = { ...props };

	return (
		<tr>
			<td>
				<img
					src={
						product.design_file == null || product.design_file == "null"
							? "assets/images/product/img-1.png"
							: product.design_file
					}
					alt="product-img"
					title="product-img"
					className="avatar-md"
				/>
			</td>
			<td className="w-20 text-justify text-wrap">
				<h5 className="font-size-14 text-justify">{product.product_name}</h5>
			</td>
			<td>$ {product.product_price}</td>
			<td>
				<div className="d-flex flex-row border-1 border-danger">
					<button
						type="button"
						disabled={product?.product_qty <= 1}
						className="btn btn-light h-25"
						onClick={() =>
							product?.product_qty <= 1
								? removeFromCart(product?.product_id)
								: addToCart({
										product_id: product?.product_id,
										qty: product?.product_qty - 1,
								  })
						}>
						-
					</button>
					<p className="d-flex border-0 justify-content-center align-items-center p-2">
						{product.product_qty}
					</p>

					<button
						type="button"
						className="btn btn-light h-25"
						onClick={() =>
							addToCart({
								product_id: product?.product_id,
								qty: product?.product_qty <= 0 ? 1 : product?.product_qty + 1,
							})
						}>
						+
					</button>
				</div>
			</td>
			<td>$ {product.product_price * product.product_qty}</td>
			<td className="text-center w-20">
				<button
					className="text-danger border-0 bg-white"
					onClick={() => removeFromCart(product?.product_id)}>
					<i className="mdi mdi-trash-can font-size-18" />
				</button>
			</td>
		</tr>
	);
}
