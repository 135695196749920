import { useEffect } from "react";

export const localStorageCustomerInfo = "customer_info";
export const localStorageTeamInfo = "team_info";
export const restrictedContainerPaths = [
	"/ejteam/login",
	"/ejcustomer/login",
	"/ejcustomer/signup",
	"/ejcustomer/forgotpassword",
	"/ejcustomer/resetpassword",
	"/ejcustomer/emailverification",
];

export const outsideClickedHandler = (ref, onClickOutside) => {
	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				onClickOutside();
			}
		};
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [ref, onClickOutside]);
};
