import moment from "moment";
import React from "react";

export default function UpdateCard(props) {
	const { update, onRead, onView } = { ...props };
	const { message, created_time } = { ...update };

	return (
		<>
			<div className="card px-3 py-3">
				<div className="card-body">
					<div className="media">
						<div className="media-body overflow-hidden">
							<h4>{message}</h4>
							<ul>
								<li>
									<span>Time :</span> {moment(created_time).format("hh:mm a")}
								</li>
								<li>
									<span>Date :</span> {moment(created_time).format("DD MMM YYYY")}
								</li>
							</ul>
						</div>
						<div className="text-primary">
							<button
								type="button"
								className="btn btn-primary waves-effect waves-light"
								onClick={onView}
							>
								View
							</button>
							<button
								type="button"
								className="btn btn-secondary waves-effect waves-light"
								onClick={onRead}
							>
								Close
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
