import React, { useMemo } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { CountryInput } from "../../../components/CountryInput";
import { useCart, usePlaceOrder } from "../../../hooks";
import { Loader } from "../../../components/Loader";
import { useAppSelector } from "../../../redux";
import { AuthStore } from "../../../redux/auth";
import { getBadRequestErrorMessage } from "../../../api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useRemoveFromCart } from "../../../hooks";

export const Checkout = () => {
	const { removeFromCart, isRemoving } = useRemoveFromCart();
	const navigation = useNavigate();
	const { user } = useAppSelector(AuthStore);
	const { cart, isLoading } = useCart();
	const { placeOrder, isPlacing } = usePlaceOrder();
	console.log(cart);

	const discount = useMemo(() => 0, [cart]);
	const subtotal = useMemo(() => {
		cart?.reduce((a, b) => a + (b?.product_price || 0) * (b?.product_qty || 0), 0);
		return cart?.reduce((a, b) => a + b?.product_price * b?.product_qty, 0);
	}, [cart]);
	const shipping = useMemo(() => 0, [cart]);
	const total = useMemo(() => {
		return subtotal - discount + shipping;
	}, [cart]);

	const isBusy = isLoading || isPlacing || isRemoving;

	const validation = Yup.object().shape({
		email: Yup.string().email("Invalid email").required("Required"),
		name: Yup.string().required("Required"),
		phone: Yup.number().typeError("Should be a number").required("Required"),
		address: Yup.string().required("Required"),
		country: Yup.string()
			.test("val", "Required", (val) => val != 0)
			.required("Required"),
		city: Yup.string().required("Required"),
		postal: Yup.number().typeError("Should be a number").required("Required"),
	});

	return (
		<Formik
			initialValues={{
				name: "",
				email: "",
				phone: "",
				address: "",
				country: "",
				city: "",
				postal: "",
			}}
			validationSchema={validation}
			onSubmit={async (values) => {
				try {
					await placeOrder({
						order_details: {
							order_value: total,
							created_user: user.userData.id,
							address_name: values.name,
							email_address: values.email,
							phone_number: values.phone,
							address: values.address,
							address_country: values.country,
							address_city: values.city,
							address_pin_code: values.postal,
						},
						product_details: cart,
					});
					toast.success("Order placed successfully");
					Promise.all(cart?.map((data) => removeFromCart(data.product_id)));
					navigation("/ejcustomer/orders");
				} catch (error) {
					toast.error(getBadRequestErrorMessage(error));
				}
			}}>
			<div>
				<Loader isBusy={isBusy} />
				<div className="page-content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-8">
								<div className="card px-2 py-2">
									<div className="card-body">
										<div id="checkout-nav-pills-wizard" className="twitter-bs-wizard">
											<div className="tab-pane" id="billing-info">
												<h5 className="card-title">Billing information</h5>
												<p className="card-title-desc">
													If several languages coalesce, the grammar of the resulting
												</p>
												<Form>
													<div>
														<div>
															<div className="row">
																<div className="col-lg-4">
																	<div className="form-group mb-4">
																		<label htmlFor="billing-name">Name</label>
																		<Field name="name">
																			{({ field }) => (
																				<input
																					{...field}
																					type="text"
																					id="billing-name"
																					className="form-control"
																					placeholder="Enter name"
																				/>
																			)}
																		</Field>
																		<ErrorMessage name="name" component="p" className="error" />
																	</div>
																</div>
																<div className="col-lg-4">
																	<div className="form-group mb-4">
																		<label htmlFor="billing-email-address">Email Address</label>
																		<Field name="email">
																			{({ field }) => (
																				<input
																					{...field}
																					type="email"
																					className="form-control"
																					id="billing-email-address"
																					placeholder="Enter email"
																				/>
																			)}
																		</Field>
																		<ErrorMessage name="email" component="p" className="error" />
																	</div>
																</div>
																<div className="col-lg-4">
																	<div className="form-group mb-4">
																		<label htmlFor="billing-phone">Phone</label>
																		<Field name="phone">
																			{({ field }) => (
																				<input
																					{...field}
																					type="number"
																					className="form-control"
																					id="billing-phone"
																					placeholder="Enter Phone no."
																				/>
																			)}
																		</Field>
																		<ErrorMessage name="phone" component="p" className="error" />
																	</div>
																</div>
															</div>
															<div className="form-group mb-4">
																<label htmlFor="billing-address">Address</label>
																<Field name="address">
																	{({ field }) => (
																		<textarea
																			{...field}
																			className="form-control"
																			id="billing-address"
																			rows={3}
																			placeholder="Enter full address"
																			defaultValue={""}
																		/>
																	)}
																</Field>
																<ErrorMessage name="address" component="p" className="error" />
															</div>
															<div className="row">
																<div className="col-lg-4">
																	<div className="form-group mb-4 mb-lg-0">
																		<label>Country</label>
																		<Field name="country">
																			{({ field }) => <CountryInput {...field} />}
																		</Field>
																		<ErrorMessage name="country" component="p" className="error" />
																	</div>
																</div>
																<div className="col-lg-4">
																	<div className="form-group mb-4 mb-lg-0">
																		<label htmlFor="billing-city">City</label>
																		<Field name="city">
																			{({ field }) => (
																				<input
																					{...field}
																					type="text"
																					className="form-control"
																					id="billing-city"
																					placeholder="Enter City"
																				/>
																			)}
																		</Field>
																		<ErrorMessage name="city" component="p" className="error" />
																	</div>
																</div>
																<div className="col-lg-4">
																	<div className="form-group mb-0">
																		<label htmlFor="zip-code">Zip / Postal code</label>
																		<Field name="postal">
																			{({ field }) => (
																				<input
																					{...field}
																					type="number"
																					className="form-control"
																					id="zip-code"
																					placeholder="Enter Postal code"
																				/>
																			)}
																		</Field>
																		<ErrorMessage name="postal" component="p" className="error" />
																	</div>
																</div>
															</div>
														</div>
													</div>
													<div className="mt-4 text-right">
														<button className="btn btn-primary" type="submit">
															Complete order
														</button>
													</div>
												</Form>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-4">
								<div className="card checkout-order-summary px-2 py-2">
									<div className="card-body">
										<div className="p-3 bg-light mb-4">
											<h5 className="font-size-14 mb-0">
												Order Summary
												{/* <span className="float-right ml-2">#SK2356</span> */}
											</h5>
										</div>
										<div className="table-responsive">
											<table className="table table-centered mb-0 table-nowrap">
												<thead>
													<tr>
														<th className="border-top-0" style={{ width: "110px" }} scope="col">
															Product
														</th>
														<th className="border-top-0" scope="col">
															Product Desc
														</th>
														<th className="border-top-0" scope="col">
															Price
														</th>
													</tr>
												</thead>
												<tbody>
													{cart?.map((product, index) => (
														<tr key={index}>
															<th scope="row">
																<img
																	src={
																		product.design_file == "null" ||
																		product.design_file == null ||
																		product.design_file == ""
																			? "assets/images/product/img-1.png"
																			: product.design_file
																	}
																	alt="product-img"
																	title="product-img"
																	className="avatar-md"
																/>
															</th>
															<td>
																<h5 className="font-size-14 text-truncate">
																	<a className="text-dark">{product.product_name}</a>
																</h5>
																<p className="text-muted mb-0">
																	$ {product.product_price} x {product.product_qty}
																</p>
															</td>
															<td>$ {product.product_price * product.product_qty}</td>
														</tr>
													))}
													<tr>
														<td colSpan={2}>
															<h6 className="m-0 text-right">Sub Total:</h6>
														</td>
														<td>${total}</td>
													</tr>
													<tr>
														<td colSpan={3}>
															<div className="bg-soft-primary p-3 rounded">
																<h5 className="font-size-14 text-primary mb-0">
																	<i className="fas fa-shipping-fast mr-2" /> Shipping
																	<span className="float-right">Free</span>
																</h5>
															</div>
														</td>
													</tr>
													<tr>
														<td colSpan={2}>
															<h6 className="m-0 text-right">Total:</h6>
														</td>
														<td>$ {total}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Formik>
	);
};
