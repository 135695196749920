import React from "react";

export const Loader = ({ isBusy }) => {
	return isBusy ? (
		<div className="loader-overlay">
			<div className="d-flex justify-content-center" id="loader">
				<div className="spinner-grow text-light" role="status">
					<span className="sr-only">Loading...</span>
				</div>
			</div>
		</div>
	) : null;
};
