import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../components/Loader";
import UpdateCard from "./components/UpdateCard";
import { useReadNotifications, useUpdates } from "../../hooks";
import { useAppSelector } from "../../redux";
import { AuthStore } from "../../redux/auth";

export const Updates = () => {
	const navigation = useNavigate();
	const { id } = useParams();
	const { user } = useAppSelector(AuthStore);
	const isTeam = user?.userData?.userRole == 1;
	const { isUpdatesLoading, updates } = useUpdates({
		userId: isTeam ? null : user?.userData?.id,
		enabled: (isTeam ? (id ? !!id : true) : false) || !!user?.userData?.id,
		filter_id: id,
	});
	const { isReading, readNotification } = useReadNotifications(isTeam ? null : user?.userData?.id);

	const isBusy = isReading || isUpdatesLoading;

	const onView = (update) => {
		const { notification_type, owner_id, ref_id } = { ...update };
		if (notification_type == "collection") {
			navigation(`/${isTeam ? "ejteam" : "ejcustomer"}/collections/${owner_id}/`);
		} else if (notification_type == "design") {
			navigation(`/${isTeam ? "ejteam" : "ejcustomer"}/chat/${ref_id}`);
		} else if (notification_type == "order") {
			navigation(`/${isTeam ? "ejteam" : "ejcustomer"}/orders`);
		} else if (notification_type == "user") {
			navigation(`/${isTeam ? "ejteam" : "ejcustomer"}/customers`);
		} else if (notification_type == "product") {
			navigation(`/${isTeam ? "ejteam" : "ejcustomer"}/collections/${ref_id}`);
		}
	};

	return (
		<div>
			<Loader isBusy={isBusy} />
			<div className="page-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-12">
							<div className="page-title-box d-flex align-items-center justify-content-between">
								<h4 className="mb-0">Updates{id ? ": EJ Customer" : ""}</h4>
								<div className="row"></div>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-xl-12">
							<div className="row">
								<div className="col-md-12">
									{updates?.map((data, index) => (
										<UpdateCard
											key={index}
											update={data}
											onView={() => onView(data)}
											onRead={() => readNotification(data?.id)}
										/>
									))}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
