import axios from "axios";
import store from "../redux";

const API_URL = "https://api.essentials-customer.com:3000/";

const apiConfig = {
	baseURL: API_URL,
	timeout: 20000,
	validateStatus: (statusCode) => statusCode >= 200 && statusCode < 300,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
};

const api = axios.create(apiConfig);

api.interceptors.response.use(
	(response) => {
		if (response?.data?.status != 200) {
			return Promise.reject(response?.data?.response || "Unknown error occured, please try again!");
		}
		return response?.data?.response;
	},
	(err) => {
		return Promise.reject(err);
	},
);

api.interceptors.request.use(
	(config) => {
		let AuthStore = store.getState()?.auth;
		let isAuthenticated = AuthStore?.isAuthenticated;
		let access_token = AuthStore?.user?.token;
		if (isAuthenticated && access_token) {
			config.headers.common["Authorization"] = "Bearer " + access_token;
		}
		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

export { api };

export function getBadRequestErrorMessage(error) {
	if (error?.message?.includes("AxiosError")) return "Something went wrong, please try again!";
	return error?.message || "Something went wrong, please try again!";
}
