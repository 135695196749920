import React from "react";

export const Footer = () => {
	return (
		<footer className="footer">
			<div className="container-fluid">
				<div className="row">
					<div className="col-sm-6">© Essentials Jewelry.</div>
					<div className="col-sm-6">
						<div className="text-sm-right d-none d-sm-block">
							Crafted with <i className="mdi mdi-heart text-danger"></i> by EJ
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};
