import { ErrorMessage, Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

export const AddCollection = (props) => {
	const { show, hide, createCollection } = { ...props };

	const validation = Yup.object().shape({
		name: Yup.string().required("Required"),
	});

	return (
		<Formik
			initialValues={{ name: "" }}
			validationSchema={validation}
			onSubmit={(values) => createCollection(values.name)}
		>
			<div>
				<div className="modal-backdrop fade show" style={{ display: show ? "block" : "none" }} />
				<div
					className="modal fade bs-example-modal-center show"
					tabIndex="-1"
					role="dialog"
					aria-labelledby="mySmallModalLabel"
					aria-modal="true"
					style={{ display: show ? "block" : "none" }}
				>
					<div className="modal-dialog modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title mt-0">New Collection</h5>
								<button
									type="button"
									className="close"
									data-dismiss="modal"
									aria-label="Close"
									onClick={hide}
								>
									<span aria-hidden="true">×</span>
								</button>
							</div>
							<div className="modal-body">
								<Form>
									<Field name="name">
										{({ field }) => (
											<input
												{...field}
												type="text"
												className="form-control"
												htmlFor="text"
												placeholder="Enter Your Collection"
											/>
										)}
									</Field>
									<ErrorMessage name="name" component="p" className="error" />
									<button type="submit" className="btn btn-primary mt-2 float-md-right">
										Create
									</button>
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Formik>
	);
};
