import React from "react";
import OtpInput from "react-otp-input";
import * as Yup from "yup";
import { Formik } from "formik";
import { AuthStore } from "../../redux/auth";
import { useCustomerEmailVerification, useSendOtp } from "../../hooks";
import { Loader } from "../../components/Loader";
import { toast } from "react-toastify";
import { getBadRequestErrorMessage } from "../../api";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../redux";

const onSuccess = () => {
	toast.success("OTP sent successfully");
};
const onError = (error) => {
	toast.error(getBadRequestErrorMessage(error));
};

export const EmailVerification = () => {
	const { user } = useAppSelector(AuthStore);
	const navigation = useNavigate();
	const { otpLoading } = useSendOtp({
		email: user?.userData?.userEmail,
		onSuccess,
		onError,
	});
	const { isVerifying, verifyOtp } = useCustomerEmailVerification();

	const isBusy = otpLoading || isVerifying;

	const validation = Yup.object().shape({
		otp: Yup.string().required("Required"),
	});

	return (
		<Formik
			initialValues={{ otp: "" }}
			validationSchema={validation}
			onSubmit={async (values) => {
				try {
					await verifyOtp({
						email: user.userData.userEmail,
						otp: values.otp,
					});
					toast.success("Verified successfully");
					navigation("/ejcustomer/updates");
				} catch (error) {
					console.log(error);
					toast.error(getBadRequestErrorMessage(error));
				}
			}}
		>
			{({ values, setFieldValue, setFieldTouched, handleSubmit, touched, errors }) => {
				return (
					<div>
						<Loader isBusy={isBusy} />
						<div className="container-fluid p-0">
							<div className="row no-gutters">
								<div className="col-lg-4">
									<div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
										<div className="w-100">
											<div className="row justify-content-center">
												<div className="col-lg-9">
													<div>
														<div className="text-center">
															<div>
																<a href="/" className="logo">
																	<img src="assets/images/logo-dark.png" height={30} alt="logo" />
																</a>
															</div>
															<h4 className="font-size-18 mt-3">OTP Verification</h4>
															<p className="text-muted">
																Please enter the one time password to verify your account.
															</p>
														</div>
														<div className="p-2 mt-5">
															<div className="alert alert-success mb-4" role="alert">
																<span>Code has been sent to </span>
																<small>{user?.userData?.userEmail}</small>
															</div>
															<form className="form-horizontal">
																<OtpInput
																	value={values.otp}
																	onChange={(otp) => setFieldValue("otp", otp)}
																	onBlur={() => setFieldTouched("otp")}
																	isInputNum
																	numInputs={6}
																	isInputSecure
																	separator={<span>-</span>}
																	containerStyle="inputs d-flex flex-row justify-content-center mt-2"
																	inputStyle="m-2 text-center rounded text-black"
																/>
																{errors.otp && touched.otp ? (
																	<p className="error">{errors?.otp}</p>
																) : null}
																<div className="mt-4 text-center">
																	<button
																		className="btn btn-primary w-md waves-effect waves-light"
																		type="button"
																		onClick={handleSubmit}
																	>
																		Submit
																	</button>
																</div>
															</form>
														</div>
														<div className="mt-5 text-center">
															<p>© 2021 Essentials Jewelry.</p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-lg-8">
									<div className="authentication-bg">{/* <div class="bg-overlay"></div> */}</div>
								</div>
							</div>
						</div>
					</div>
				);
			}}
		</Formik>
	);
};
