import React from "react";

export const ProductInfoCard = ({ designs }) => {
	return (
		<div className="card-body border-bottom py-3 px-3">
			<table className="chat-table ">
				<tbody>
					<tr>
						<td>UV Style :</td>
						<td style={{ minWidth: "100px" }}>{designs?.uv_style}</td>
					</tr>
					<tr>
						<td>Design Category :</td>
						<td>{designs?.design_category}</td>
					</tr>
					<tr>
						<td>Metal :</td>
						<td>{designs?.metal}</td>
					</tr>
					<tr>
						<td>Design Size :</td>
						<td>{designs?.design_size}</td>
					</tr>
					<tr>
						<td>Stone Name :</td>
						<td>{designs?.stone_name}</td>
					</tr>
					<tr>
						<td>Stone Shape :</td>
						<td>{designs?.stone_shape}</td>
					</tr>
					<tr>
						<td>Stone Cut :</td>
						<td>{designs?.stone_cut}</td>
					</tr>
					<tr>
						<td>Stone Size :</td>
						<td>{designs?.stone_size}</td>
					</tr>
					<tr>
						<td>Stone Qty :</td>
						<td>{designs?.stone_qty}</td>
					</tr>
					<tr>
						<td>Plating :</td>
						<td>{designs?.plating}</td>
					</tr>
					<tr>
						<td>Finding :</td>
						<td>{designs?.finding}</td>
					</tr>
					{/* <tr>
                            <td>Sample Qty :</td>
                            <td>10</td>
                          </tr> */}
				</tbody>
			</table>
		</div>
	);
};
