import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { useChat, useDesigns, useSendMessage } from "../../hooks";
import S3 from "react-aws-s3";
import { S3_CONFIG } from "../../components/constants";
import { useAppSelector } from "../../redux";
import { AuthStore } from "../../redux/auth";
import { FilesModal } from "./components/FilesModal";
import { ProductInfoCard } from "./components/ProductInfoCard";
import { ProductStatus } from "./components/ProductStatus";
import { toast } from "react-toastify";
import { getBadRequestErrorMessage } from "../../api";

const ReactS3Client = new S3(S3_CONFIG);

export const Chat = () => {
	const chatRef = useRef(null);
	const { user } = useAppSelector(AuthStore);
	const { id } = useParams();
	const { chat } = useChat(id);
	const { designs, isDesignsLoading } = useDesigns(id);
	const { isSending, send } = useSendMessage(id);
	const isTeam = user?.userData?.userRole == 1;

	const isBusy = isSending || isDesignsLoading;

	const [showFiles, setShowFiles] = useState(false);
	const [message, setMessage] = useState("");

	useEffect(() => {
		chatRef?.current?.scrollIntoView({ behavior: "smooth" });
	}, [chat]);

	const files = useMemo(() => {
		const filtered_messages = chat?.filter((msg) => !msg?.message || msg?.message == "null");
		return [
			{
				attachment_name:
					designs?.design_file != null &&
					designs?.design_file != "null" &&
					designs?.design_file != ""
						? "Design File"
						: "Design File not found",
				attachment_url:
					designs?.design_file != null &&
					designs?.design_file != "null" &&
					designs?.design_file != ""
						? designs.design_file
						: "",
				message: null,
			},
			...(filtered_messages || []),
		];
	}, [chat, designs]);

	const uploadAttachment = async (file) => {
		if (file) {
			let newFileName = Date.now() + "." + file["type"].split("/")[1];
			ReactS3Client.uploadFile(file, newFileName)
				.then((data) => {
					let url = data.location;
					send({
						product_id: id,
						message: null,
						from_user: user.userData.userName,
						to_role: user.userData.userRole != 1 ? 0 : 1,
						attachment_url: url,
						attachment_name: file.name,
					}).catch((error) => toast.error(getBadRequestErrorMessage(error)));
				})
				.catch(() => toast.error("Upload files failed"));
		}
	};

	const sendMessage = async () => {
		try {
			await send({
				product_id: id,
				message: message,
				from_user: user.userData.userName,
				to_role: user.userData.userRole != 1 ? 0 : 1,
			});
			setMessage("");
		} catch (error) {
			toast.error("Sending Failed");
		}
	};

	return (
		<div>
			<Loader isBusy={isBusy} />
			<div className="page-content">
				<div className="container-fluid">
					<FilesModal
						files={files}
						show={showFiles}
						hide={() => setShowFiles(false)}
						currentUserId={user?.userData?.id}
					/>
					<div className="d-lg-flex mb-4">
						<div className="chat-leftsidebar">
							<div className="p-3 border-bottom">
								<div className="media">
									<div className="align-self-center mr-3">
										<img
											src={designs?.design_file || "assets/images/product/img-1.png"}
											className="avatar-xs1 rounded-circle1"
										/>
									</div>
									<div className="media-body">
										<h5 className="font-size-15 mt-4">{designs?.name}</h5>
									</div>
									<div>
										<div className="dropdown chat-noti-dropdown"></div>
									</div>
								</div>
							</div>
							<ProductInfoCard designs={designs} />
							{isTeam ? (
								<div className="text-primary py-4 px-3">
									<Link to={"/ejteam/editdesign/" + id}>
										<button type="button" className="btn btn-primary waves-effect waves-light">
											Edit Details
										</button>
									</Link>
									<Link to={"/ejteam/editprice/" + id}>
										<button type="button" className="btn btn-secondary waves-effect waves-light">
											Update Price
										</button>
									</Link>
								</div>
							) : (
								<div className="text-primary px-3">
									<ProductStatus product_status={designs?.product_status} />
								</div>
							)}
						</div>
						<div className="w-100 user-chat mt-4 mt-sm-0">
							<div className="p-3 px-lg-4 user-chat-border">
								<div className="row">
									<div className="col-md-10 col-8">
										{/* <h5 className="font-size-15 mb-1 text-truncate">Prashanth : Summer</h5>
										<p className="text-muted text-truncate mb-0">
											<i className="mdi mdi-circle text-success align-middle mr-1" />
											Active now
										</p> */}
									</div>
									<button
										type="button"
										className="btn btn-primary waves-effect waves-light"
										onClick={() => setShowFiles(true)}>
										<span className="d-none d-sm-inline-block">Show Files</span>
										<i className="mdi mdi-attachment lg-6" />
									</button>
									<div className="col-md-8 col-6">
										<ul className="list-inline pe-none user-chat-nav text-right mb-0">
											<li className="list-inline-item d-inline-block d-sm-none"></li>
											<li className="d-none d-sm-inline-block"></li>
											<li className="list-inline-item m-0 d-none d-sm-inline-block"></li>
											<li className="list-inline-item"></li>
										</ul>
									</div>
								</div>
							</div>
							<div className="px-lg-2">
								<div className="chat-conversation p-3">
									<ul
										className="list-unstyled mb-0 pr-3"
										data-simplebar
										style={{ height: 350, overflowY: "auto" }}>
										{chat?.map((message, index) => {
											if (message.from_user_id == user.userData.id) {
												return (
													<li className="right" key={index}>
														<div className="conversation-list">
															<div className="ctext-wrap">
																<div className="conversation-name">{message.from_user}</div>
																{message.attachment_name &&
																	message.attachment_url &&
																	message.attachment_name != "undefined" &&
																	message.attachment_name != "null" &&
																	message.attachment_url != "undefined" &&
																	message.attachment_url != "null" && (
																		<a
																			href={message.attachment_url}
																			target="_blank"
																			rel="noreferrer">
																			<div className="ctext-wrap-content">
																				<p className="mb-0">
																					<i
																						className="mdi mdi-attachment lg-2"
																						style={{
																							marginRight: "5px",
																						}}
																					/>
																					{message.attachment_name}
																				</p>
																			</div>
																		</a>
																	)}
																{message.message && message.message != "null" && (
																	<div className="ctext-wrap-content">
																		<p className="mb-0">{message.message}</p>
																	</div>
																)}
																<p className="chat-time mb-0">
																	<i className="bx bx-time-five align-middle mr-1" />
																	{moment(message.message_time).format("hh:mm a")}
																</p>
															</div>
														</div>
													</li>
												);
											} else {
												return (
													<li key={index}>
														<div className="conversation-list">
															<div className="chat-avatar">
																<img src="assets/images/users/avatar-2.jpg" />
															</div>
															<div className="ctext-wrap">
																<div className="conversation-name">{message.from_user}</div>
																{message.attachment_name &&
																	message.attachment_url &&
																	message.attachment_name != "undefined" &&
																	message.attachment_name != "null" &&
																	message.attachment_url != "undefined" &&
																	message.attachment_url != "null" && (
																		<a
																			href={message.attachment_url}
																			target="_blank"
																			rel="noreferrer">
																			<div className="ctext-wrap-content">
																				<p className="mb-0">
																					<i
																						className="mdi mdi-attachment lg-2"
																						style={{
																							marginRight: "5px",
																						}}
																					/>
																					{message.attachment_name}
																				</p>
																			</div>
																		</a>
																	)}
																{message.message && message.message != "null" && (
																	<div className="ctext-wrap-content">
																		<p className="mb-0">{message.message}</p>
																	</div>
																)}
																<p className="chat-time mb-0">
																	<i className="mdi mdi-clock-outline align-middle mr-1" />
																	{moment(message.message_time).format("hh:mm a")}
																</p>
															</div>
														</div>
													</li>
												);
											}
										})}
										<li style={{ float: "left", clear: "both" }} ref={chatRef}></li>
									</ul>
								</div>
							</div>
							<div className="px-lg-3">
								<div className="p-3 chat-input-section">
									<div className="row">
										<div className="col-lg-10">
											<div className="position-relative">
												<textarea
													required
													className="form-control chat-input"
													rows={5}
													placeholder="Enter Message..."
													spellCheck="false"
													value={message}
													onChange={(e) => setMessage(e.target.value)}
												/>
											</div>
										</div>
										<div className="col-lg-2">
											<input
												type="file"
												className="custom-file-input"
												style={{ display: "none" }}
												id="validatedCustomFile"
												required
												// webkitdirectory
												onChange={(e) => uploadAttachment(e.target.files[0])}
											/>
											<label htmlFor="validatedCustomFile">
												<span className="btn btn-primary waves-effect w-md waves-light attchment mb-2">
													<i className="mdi mdi-attachment lg-2" />
												</span>
											</label>

											<button
												type="submit"
												className="btn btn-primary chat-send w-md waves-effect waves-light"
												onClick={sendMessage}>
												<span className="d-none d-sm-inline-block mr-2">Send</span>
												<i className="mdi mdi-send" />
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
