import React from "react";
import { useCustomers, useRemoveCustomer } from "../../../hooks";
import { Loader } from "../../../components/Loader";
import { Link } from "react-router-dom";

export const Customers = () => {
	const { customers, isLoading } = useCustomers();
	const { isRemoving, remove } = useRemoveCustomer();

	const isBusy = isLoading || isRemoving;

	return (
		<div>
			<Loader isBusy={isBusy} />
			<div className="page-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card px-2 py-2">
								<div className="card-body  pt-0">
									<div className="table-responsive">
										<table
											className="table table-centered datatable dt-responsive nowrap "
											style={{
												borderCollapse: "collapse",
												borderSpacing: 0,
												width: "100%",
											}}>
											<thead className="thead-light">
												<tr>
													<th>Name</th>
													<th>Email</th>
													<th>Phone Number</th>
													<th>Joining Date</th>
													<th style={{ width: 120 }}>Action</th>
												</tr>
											</thead>
											<tbody>
												{customers.map((customer, index) => (
													<tr key={index}>
														<td>{customer?.userName}</td>
														<td>{customer?.userEmail}</td>
														<td>{customer?.MobileNumber}</td>
														<td>{customer?.joiningDate}</td>
														<td>
															<div className="d-flex align-items-center">
																<Link
																	to={"/ejteam/editcustomer/" + customer?.id}
																	className="mr-3 text-primary"
																	data-toggle="tooltip"
																	data-placement="top"
																	title
																	data-original-title="Edit">
																	<i className="mdi mdi-pencil font-size-18" />
																</Link>
																<button
																	className="btn border-0"
																	onClick={() => remove(customer?.id)}>
																	<a
																		className="text-danger"
																		data-toggle="tooltip"
																		data-placement="top"
																		title
																		data-original-title="Delete">
																		<i className="mdi mdi-trash-can font-size-18" />
																	</a>
																</button>
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
