import React from "react";
import * as Yup from "yup";
import { Loader } from "../../components/Loader";
import { useParams, useNavigate } from "react-router";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useCreateUpdateDesignsTeam, useDesigns } from "../../hooks";
import { toast } from "react-toastify";
import { getBadRequestErrorMessage } from "../../api";

export const EditDesignDetails = () => {
	const { id } = useParams();
	const navigation = useNavigate();

	const { designs, isDesignsLoading } = useDesigns(id);
	const { createUpdateDesign, isCreating } = useCreateUpdateDesignsTeam(id);

	const isBusy = isCreating;

	if (isDesignsLoading) return <Loader isBusy={isDesignsLoading} />;

	// const updateDesignDetails = () => {
	// 	setError("");
	// 	setSuccess("");
	// 	const isLoggedInUser = cookies.get("team");
	// 	fetch(API_URL + "designs/" + id, {
	// 		method: "put",
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 			Authorization: "Bearer " + isLoggedInUser.token,
	// 		},
	// 		body: JSON.stringify(Design),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((res) => {
	// 			if (res.status == 200) {
	// 				setSuccess(res.response);
	// 				updateProductDetails();
	// 			} else {
	// 				setError(res.error);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.error(error);
	// 			setError("Network request error");
	// 		});
	// };

	// const updateProductDetails = async () => {
	// 	Promise.all(
	// 		Products.map(async (product) => {
	// 			let { product_name, product_price } = product;
	// 			if (product_name.trim() != "" && product_price != 0) {
	// 				if (product.id != undefined) {
	// 					//update product
	// 					await updateProduct(product, "put");
	// 				} else {
	// 					//add product
	// 					await updateProduct(product, "post");
	// 				}
	// 			} else if (product_name.trim() == "" && product_price == 0) {
	// 				if (product.id != undefined) {
	// 					//delete product
	// 					await updateProduct(product, "delete");
	// 				}
	// 			} else {
	// 				// throw error
	// 			}
	// 			return product;
	// 		}),
	// 	).then(() => {
	// 		getDesignDetails();
	// 	});
	// };

	// const updateProduct = async (product, method) => {
	// 	let body = {
	// 		collection_id: Design.collection_id,
	// 		design_category: Design.design_category,
	// 		product_category: Design.product_category,
	// 		product_status: Design.product_status,
	// 		design_id: id,
	// 		product_id: product.id,
	// 		product_name: product.product_name,
	// 		product_price: parseInt(product.product_price),
	// 	};
	// 	const isLoggedInUser = cookies.get("team");
	// 	return fetch(API_URL + "products/", {
	// 		method: method,
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 			Authorization: "Bearer " + isLoggedInUser.token,
	// 		},
	// 		body: JSON.stringify(body),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((res) => {
	// 			if (res.status == 200) {
	// 				setSuccess(res.response);
	// 			} else {
	// 				setError(res.error);
	// 			}
	// 			return;
	// 		})
	// 		.catch((error) => {
	// 			console.error(error);
	// 			setError("Network request error");
	// 			return;
	// 		});
	// };

	// const updateProductName = (index, value) => {
	// 	let products = Products;
	// 	products[index].product_name = value;
	// 	setProducts([...products]);
	// };
	// const updateProductPrice = (index, value) => {
	// 	let products = Products;
	// 	products[index].product_price = value;
	// 	setProducts([...products]);
	// };

	const validation = Yup.object().shape({
		product_category: Yup.string().required("Required"),
		uv_style: Yup.string(),
		metal: Yup.string(),
		design_size: Yup.string(),
		stone_name: Yup.string(),
		stone_shape: Yup.string(),
		finding: Yup.string(),
		design_category: Yup.string(),
		stone_cut: Yup.string(),
		stone_size: Yup.string(),
		stone_qty: Yup.string(),
		plating: Yup.string(),
		product_status: Yup.string(),
	});

	return (
		<Formik
			initialValues={{
				//used
				product_category: designs?.product_category || "",
				uv_style: designs?.uv_style || "",
				metal: designs?.metal || "",
				design_size: designs?.design_size || "",
				stone_name: designs?.stone_name || "",
				stone_shape: designs?.stone_shape || "",
				finding: designs?.finding || "",
				design_category: designs?.design_category || "",
				stone_cut: designs?.stone_cut || "",
				stone_size: designs?.stone_size || "",
				stone_qty: designs?.stone_qty || "",
				plating: designs?.plating || "",
				product_status: designs?.product_status || "",
				//unused
				id: designs?.id || "",
				collection_id: designs?.collection_id || "",
				name: designs?.name || "",
				description: designs?.description || "",
				design_file: designs?.design_file || "",
				product_price: designs?.product_price || "",
				created_by: designs?.created_by || "",
				created_date: designs?.created_date || "",
			}}
			validationSchema={validation}
			onSubmit={async (values) => {
				try {
					await createUpdateDesign(values);
					toast.success("Details updated successfully");
					navigation(-1);
				} catch (error) {
					toast.error(getBadRequestErrorMessage(error));
				}
			}}>
			{({ values, setFieldValue }) => {
				return (
					<div>
						<Loader isBusy={isBusy} />
						<div className="page-content">
							<div className="container-fluid">
								<div className="row">
									<div className="col-lg-12">
										<div className="card px-2 py-2">
											<div className="card-body">
												<Form>
													<h4 className="text-truncate lead">Categories</h4>
													<div className="row">
														<div className="col-lg-6">
															<div className="form-group mb-4">
																<Field name="product_category">
																	{({ field }) => (
																		<select
																			{...field}
																			className="form-control"
																			id="product_category">
																			<option value={null}>Select Category</option>
																			<option value={"Necklace"}>Necklace</option>
																			<option value="Earrings">Earrings</option>
																			<option value="Cuff Bangles">Cuff Bangles</option>
																			<option value="Bracelets">Bracelets</option>
																			<option value="Ring">Ring</option>
																			<option value="Studs">Studs</option>
																			<option value="Pendants">Pendants</option>
																			<option value="other">Others </option>
																		</select>
																	)}
																</Field>
																<ErrorMessage
																	name="product_category"
																	component="p"
																	className="error"
																/>
															</div>
														</div>
													</div>
													<hr />
													<label className="text-truncate mb-3 lead" htmlFor="uv_style">
														Client Style
													</label>
													<div className="row">
														<div className="col-lg-6">
															<div className="form-group mb-4">
																<label className="lead font-size-15" htmlFor="uv_style">
																	UV Style#
																</label>
																<Field name="uv_style">
																	{({ field }) => (
																		<input
																			{...field}
																			type="text"
																			className="form-control"
																			id="uv_style"
																			placeholder="Enter Here.."
																		/>
																	)}
																</Field>
																<ErrorMessage name="uv_style" component="p" className="error" />
															</div>
															<div className="form-group mb-4">
																<label className="lead font-size-15" htmlFor="metal">
																	Metal
																</label>
																<Field name="metal">
																	{({ field }) => (
																		<input
																			{...field}
																			type="text"
																			className="form-control"
																			id="metal"
																			placeholder="Enter Here.."
																		/>
																	)}
																</Field>
																<ErrorMessage name="metal" component="p" className="error" />
															</div>
															<div className="form-group mb-4">
																<label className="lead font-size-15" htmlFor="design_size">
																	Design Size
																</label>
																<Field name="design_size">
																	{({ field }) => (
																		<input
																			{...field}
																			type="text"
																			className="form-control"
																			id="design_size"
																			placeholder="Enter Here.."
																		/>
																	)}
																</Field>
																<ErrorMessage name="design_size" component="p" className="error" />
															</div>
															<div className="form-group mb-4">
																<label className="lead font-size-15" htmlFor="stone_name">
																	Stone Name
																</label>
																<Field name="stone_name">
																	{({ field }) => (
																		<input
																			{...field}
																			type="text"
																			className="form-control"
																			id="stone_name"
																			placeholder="Enter Here.."
																		/>
																	)}
																</Field>
																<ErrorMessage name="stone_name" component="p" className="error" />
															</div>
															<div className="form-group mb-4">
																<label className="lead font-size-15" htmlFor="stone_shape">
																	Stone Shape
																</label>
																<Field name="stone_shape">
																	{({ field }) => (
																		<input
																			{...field}
																			type="text"
																			className="form-control"
																			id="stone_shape"
																			placeholder="Enter Here.."
																		/>
																	)}
																</Field>
																<ErrorMessage name="stone_shape" component="p" className="error" />
															</div>
															<div className="form-group mb-4">
																<label className="lead font-size-15" htmlFor="finding">
																	Plating
																</label>
																<Field name="finding">
																	{({ field }) => (
																		<input
																			{...field}
																			type="text"
																			className="form-control"
																			id="finding"
																			placeholder="Enter Here.."
																		/>
																	)}
																</Field>
																<ErrorMessage name="finding" component="p" className="error" />
															</div>
														</div>
														<div className="col-lg-6">
															<div className="mt-4 mt-lg-0">
																<div className="form-group mb-4">
																	<label className="lead font-size-15" htmlFor="design_category">
																		Design Category
																	</label>
																	<Field name="design_category">
																		{({ field }) => (
																			<input
																				{...field}
																				type="text"
																				className="form-control"
																				id="design_category"
																				placeholder="Enter Here.."
																			/>
																		)}
																	</Field>
																	<ErrorMessage
																		name="design_category"
																		component="p"
																		className="error"
																	/>
																</div>
																<div className="form-group mb-4">
																	<label className="lead font-size-15" htmlFor="stone_cut">
																		Stone Cut
																	</label>
																	<Field name="stone_cut">
																		{({ field }) => (
																			<input
																				{...field}
																				type="text"
																				className="form-control"
																				id="stone_cut"
																				placeholder="Enter Here.."
																			/>
																		)}
																	</Field>
																	<ErrorMessage name="stone_cut" component="p" className="error" />
																</div>
																<div className="form-group mb-4">
																	<label className="lead font-size-15" htmlFor="stone_size">
																		Stone Size
																	</label>
																	<Field name="stone_size">
																		{({ field }) => (
																			<input
																				{...field}
																				type="text"
																				className="form-control"
																				id="stone_size"
																				placeholder="Enter Here.."
																			/>
																		)}
																	</Field>
																	<ErrorMessage name="stone_size" component="p" className="error" />
																</div>
																<div className="form-group mb-4">
																	<label className="lead font-size-15" htmlFor="stone_qty">
																		Stone Quantity
																	</label>
																	<Field name="stone_qty">
																		{({ field }) => (
																			<input
																				{...field}
																				type="text"
																				className="form-control"
																				id="stone_qty"
																				placeholder="Enter Here.."
																			/>
																		)}
																	</Field>
																	<ErrorMessage name="stone_qty" component="p" className="error" />
																</div>
																<div className="form-group mb-4">
																	<label className="lead font-size-15" htmlFor="plating">
																		Plating
																	</label>
																	<Field name="plating">
																		{({ field }) => (
																			<input
																				{...field}
																				type="text"
																				className="form-control"
																				id="plating"
																				placeholder="Enter Here.."
																			/>
																		)}
																	</Field>
																	<ErrorMessage name="plating" component="p" className="error" />
																</div>
															</div>
														</div>
													</div>
													{/* <h4 className="text-truncate mt-3">Update Price</h4>
													{products.map((prod, index) => (
														<div className="row" key={index}>
															<div className="col-lg-6">
																<div>
																	<div className="form-group mb-4" key={index}>
																		<input
																			type="text"
																			className="form-control"
																			htmlFor="text"
																			placeholder="Enter Product Name"
																			value={prod.product_name}
																			// onChange={(e) => {
																			// 	updateProductName(index, e.target.value);
																			// }}
																		/>
																	</div>
																</div>
															</div>
															<div className="col-lg-6">
																<div>
																	<div className="form-group mb-4">
																		<div className="input-group mb-3">
																			<div className="input-group-prepend">
																				<span className="input-group-text">$</span>
																			</div>
																			<input
																				type="text"
																				className="form-control"
																				aria-label="Amount (to the nearest dollar)"
																				value={prod.product_price}
																				// onChange={(e) => {
																				// 	updateProductPrice(index, e.target.value);
																				// }}
																			/>
																			<div className="input-group-append">
																				<span className="input-group-text">.00</span>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													))} */}
													<h4 className="text-truncate mt-3">Product Status</h4>
													<div className="row mb-3">
														<div className="col-lg-6">
															<div className="custom-control custom-checkbox mb-3">
																<input
																	type="checkbox"
																	id="customCheck1"
																	className="custom-control-input"
																	checked={values.product_status >= 1}
																	onChange={() =>
																		setFieldValue(
																			"product_status",
																			values.product_status >= 1 ? 0 : 1,
																		)
																	}
																/>
																<label htmlFor="customCheck1" className="custom-control-label">
																	Designer have Completed the design.
																</label>
															</div>
															<div className="custom-control custom-checkbox mb-3">
																<input
																	type="checkbox"
																	id="customCheck2"
																	className="custom-control-input"
																	checked={values.product_status >= 2}
																	onChange={() =>
																		setFieldValue(
																			"product_status",
																			values.product_status >= 2 ? 1 : 2,
																		)
																	}
																/>
																<label htmlFor="customCheck2" className="custom-control-label">
																	Sample Design is ready.
																</label>
															</div>
															<div className="custom-control custom-checkbox mb-3">
																<input
																	type="checkbox"
																	id="customCheck3"
																	className="custom-control-input"
																	checked={values.product_status >= 3}
																	onChange={() =>
																		setFieldValue(
																			"product_status",
																			values.product_status >= 3 ? 2 : 3,
																		)
																	}
																/>
																<label htmlFor="customCheck3" className="custom-control-label">
																	Plating with gold is getting ready.
																</label>
															</div>
															<div className="custom-control custom-checkbox mb-3">
																<input
																	type="checkbox"
																	id="customCheck4"
																	className="custom-control-input"
																	checked={values.product_status >= 4}
																	onChange={() =>
																		setFieldValue(
																			"product_status",
																			values.product_status >= 4 ? 3 : 4,
																		)
																	}
																/>
																<label htmlFor="customCheck4" className="custom-control-label">
																	Price will be added tomorrow.
																</label>
															</div>
															<ErrorMessage name="product_status" component="p" className="error" />
														</div>
													</div>
													<button
														type="submit"
														className="btn btn-primary waves-effect waves-light">
														Save
													</button>
													<button
														type="button"
														className="btn btn-secondary waves-effect waves-light"
														onClick={() => navigation("/ejteam/chat/" + designs?.id)}>
														Cancel
													</button>
												</Form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}}
		</Formik>
	);
};
