import { api } from "../index";

export const getCollections = (id) =>
	api
		.get(id ? `collections/${id}` : "collections")
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const createCollection = (name, id) =>
	api
		.post(
			"collections",
			JSON.stringify({
				collectionName: name,
				userId: id,
			}),
		)
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const getDesignsByCollections = (id) =>
	api
		.get(`designs/bycollection/${id}`)
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const getProductByDesignId = (id) =>
	api
		.get(`products/bydesign/${id}`)
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const removeProduct = (id) =>
	api
		.delete(`products/${id}`)
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const addProduct = (data) =>
	api
		.post("products", JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const updateProduct = (data) =>
	api
		.put("products", JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const uploadDesignsCustomer = (data) =>
	api
		.post("designs", JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const uploadDesignsTeam = (id, data) =>
	api
		.put(`designs/${id}`, JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const getChat = (id) =>
	api
		.get(`chat/${id}`)
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const getDesigns = (id) =>
	api
		.get(`designs/${id}`)
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const sendMessage = (data) =>
	api
		.post("chat", JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});
