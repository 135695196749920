import React, { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../containers/Footer";
import { Header } from "../containers/Header";
import { NavBar } from "../containers/NavBar";
import { useAppDispatch, useAppSelector } from "../redux";
import { AuthStore, userLoggedin, userLoginFailed } from "../redux/auth";
import { localStorageCustomerInfo, localStorageTeamInfo, restrictedContainerPaths } from "./helper";

export const AuthenticationWrapper = ({ children }) => {
	const navigation = useNavigate();
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();
	const isTeam = pathname?.includes("ejteam");
	const { isAuthenticated, user } = useAppSelector(AuthStore);

	useMemo(() => {
		if (!isAuthenticated) navigation("/");
	}, [isAuthenticated]);

	useEffect(() => {
		const customer = localStorage.getItem(localStorageCustomerInfo);
		const team = localStorage.getItem(localStorageTeamInfo);
		if (!customer && !team) return dispatch(userLoginFailed());
		dispatch(userLoggedin(JSON.parse(customer) || JSON.parse(team)));
	}, []);

	if (pathname != "/" && !restrictedContainerPaths?.includes(pathname))
		return (user?.userData?.userRole == 1 && isTeam) ||
			(user?.userData?.userRole == 2 && !isTeam) ? (
			<body data-topbar="dark" data-layout="horizontal">
				<Header />
				<NavBar />
				<div id="layout-wrapper">
					<div className="main-content">
						{children}
						<Footer />
					</div>
				</div>
			</body>
		) : (
			<div className="page-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card px-2 py-2 pt-3">
								<div className="card-body  pt-0">
									<h3 className="my-5 d-flex justify-content-center text-muted">
										Please login with customer to view
									</h3>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	return <>{children}</>;
};
