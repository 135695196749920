import moment from "moment";
import React from "react";
import { useParams } from "react-router";
import Pdf from "react-to-pdf";
import { Loader } from "../../components/Loader";
import { useInvoice } from "../../hooks";

export const Invoice = () => {
	const ref = React.createRef();
	const { id } = useParams();

	const { invoice, isInvoiceLoading } = useInvoice(id);

	if (invoice)
		return (
			<>
				<div>
					<Loader isBusy={isInvoiceLoading} />
					<div className="page-content">
						<div className="container-fluid">
							<div className="invoice-box" ref={ref}>
								<table cellPadding={0} cellSpacing={0}>
									<tbody>
										<tr className="top">
											<td colSpan={2}>
												<table>
													<tbody>
														<tr>
															<td className="title">
																<img
																	src="assets/images/logo-dark.png"
																	style={{
																		width: "100%",
																		maxWidth: "300px",
																	}}
																/>
															</td>
															<td>
																Invoice #: {invoice.id}
																<br />
																Created: {moment(invoice.created_date).format("DD MMMM YYYY")}
																<br />
															</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
										<tr className="information">
											<td colSpan={2}>
												<table>
													<tbody>
														<tr>
															<td>
																Essential Jewelery, Inc.
																<br />
																{/* <br /> */}
																Jaipur, IN 12345
															</td>
															<td>
																{invoice.address_name}
																<br />
																{invoice.address_city}
																<br />
																{invoice.address_pin_code}
															</td>
														</tr>
													</tbody>
												</table>
											</td>
										</tr>
										<tr className="heading">
											<td>Payment Method</td>
											<td>Check #</td>
										</tr>
										<tr className="details">
											<td>Offline</td>
											<td>{invoice.order_value}</td>
										</tr>
										<tr className="heading">
											<td>Item</td>
											<td>Price</td>
										</tr>
										<tr className="item">
											<td>
												{invoice.product_name} ({invoice.product_qty})
											</td>
											<td>${invoice.product_price}</td>
										</tr>
										{/* <tr className="item">
                                        <td>Hosting (3 months)</td>
                                        <td>$75.00</td>
                                    </tr> */}
										{/* <tr className="item last">
                                        <td>Domain name (1 year)</td>
                                        <td>$10.00</td>
                                    </tr> */}
										<tr className="total">
											<td />
											<td>Total: ${invoice.total_price}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<Pdf targetRef={ref} filename="invoice.pdf">
							{({ toPdf }) => (
								<div className="d-flex justify-content-center">
									<button
										type="button"
										className="my-5 w-50 btn btn-primary btn-lg btn-block  waves-effect waves-light"
										onClick={toPdf}>
										Download Invoice
									</button>
								</div>
							)}
						</Pdf>
					</div>
				</div>
			</>
		);
	return <></>;
};
