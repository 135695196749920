import React from "react";

export class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		console.log(error);
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		console.log(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div className="jumbotron jumbotron-fluid p-5">
					<h1 className="display-4">Something went wrong.</h1>
					<hr className="my-4" />
					<p>Unknown error occured, please try again.</p>
				</div>
			);
		}

		return <>{this.props.children}</>;
	}
}
