import React, { useState } from "react";
import { useCollections, useCreateCollection } from "../../hooks";
import { AddCollection } from "./components/AddCollection";
import { useAppSelector } from "../../redux";
import { AuthStore } from "../../redux/auth";
import { Loader } from "../../components/Loader";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";

export const Collections = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { user } = useAppSelector(AuthStore);
	const isTeam = user?.userData?.userRole == 1;

	const [sort, setSort] = useState(0);
	const [addCollection, setAddCollection] = useState(false);

	const { collections, isCollectionsLoading } = useCollections(
		sort,
		id ? id : null,
		id ? !!id : true,
	);
	const { createCollection, isCreating } = useCreateCollection(() => setAddCollection(false));

	const isBusy = isCollectionsLoading || isCreating;

	return (
		<div>
			<Loader isBusy={isBusy} />
			<div className="page-content">
				<div className="container-fluid">
					{!isTeam && (
						<AddCollection
							show={addCollection}
							hide={() => setAddCollection(false)}
							createCollection={(name) => createCollection({ name, id: user?.userData?.id })}
						/>
					)}
					<div className="row">
						<div className="col-12">
							<div className="page-title-box d-flex align-items-center justify-content-between">
								{!isTeam && (
									<button
										type="button"
										className="btn btn-primary waves-effect waves-light"
										data-toggle="modal"
										data-target=".bs-example-modal-center"
										onClick={() => setAddCollection(true)}>
										<i className="ri-add-line align-middle mr-2" /> Add New Collection
									</button>
								)}

								<div className="page-title-right">
									<ul className="list-inline pe-none my-3 ecommerce-sortby-list">
										<li className="list-inline-item">
											<span className="font-weight-medium font-family-secondary">Sort by:</span>
										</li>
										<li className={sort == 0 ? "list-inline-item active" : "list-inline-item"}>
											<a onClick={() => setSort(0)}>Old to New</a>
										</li>
										<li className={sort == 1 ? "list-inline-item active" : "list-inline-item"}>
											<a onClick={() => setSort(1)}>New to Old</a>
										</li>
										<li className={sort == 2 ? "list-inline-item active" : "list-inline-item"}>
											<a onClick={() => setSort(2)}>Alphabetically A - Z</a>
										</li>
										<li className={sort == 3 ? "list-inline-item active" : "list-inline-item"}>
											<a onClick={() => setSort(3)}>Alphabetically Z - A</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
						<div className="row">
							{collections.map((collection, index) => (
								<div className="col-xl-3 col-sm-6" key={index} onClick={()=> navigate(`/${isTeam ? "ejteam" : "ejcustomer"}/${collection.id}/products`)}>
									<div className="card">
										<div className="card-body">
											<div className="text-center">
												<img
													src={
														collection.design_file == "null" || collection.design_file == null
															? "assets/images/collection/collection1.jpg"
															: collection.design_file
													}
													alt
													className="avatar-sm mb-1"
												/>
												<div className="media-body">
													<h5 className="text-truncate">
														<p className="text-dark">
															{collection.collectionName}
														</p>
													</h5>
												</div>
											</div>
											<hr className="my-3" />
											<div className="row text-center">
												<div className="col-6 text-left">
													<p className="text-muted mb-2">Products</p>
													<h6>{collection.totalProducts}</h6>
												</div>
												<div className="col-6 text-left">
													<p className="text-muted mb-2">Created Date</p>
													<h6>
														<i className="mdi mdi-calendar-month-outline mr-1" />
														{moment(collection.createdDate).format("DD MMM YYYY")}
													</h6>
												</div>
											</div>
										</div>
									</div>
								</div>
							))}
						</div>
				</div>
			</div>
		</div>
	);
};
