import React from "react";
import { Router } from "./Router";
import { Provider } from "react-redux";
import store from "./redux";
import { queryClient } from "./api/client";
import { QueryClientProvider } from "@tanstack/react-query";
import { AuthenticationWrapper } from "./Utils/AuthenticationWrapper";
import { ErrorBoundary } from "./Utils/ErrorBoundries";
import { Toast } from "./components/Toast";

function App() {
	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<ErrorBoundary>
					<AuthenticationWrapper>
						<Router />
						<Toast />
					</AuthenticationWrapper>
				</ErrorBoundary>
			</Provider>
		</QueryClientProvider>
	);
}

export default App;
