import { api } from "../index";

export const getOrders = (id) =>
	api
		.get(id ? `orders/${id}` : "orders")
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const getInvoice = (id) =>
	api
		.get(`orders/details/${id}`)
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});
