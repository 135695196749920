import React, { useState } from "react";
import { useDesignsByCollections } from "../../hooks";
import { useParams } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { Link, useNavigate} from "react-router-dom";
import { useAppSelector } from "../../redux";
import { AuthStore } from "../../redux/auth";

export const Products = () => {
	const navigate = useNavigate();
	const { id: product_id } = useParams();
	const { user } = useAppSelector(AuthStore);
	const isTeam = user?.userData?.userRole == 1;

	const [sort, setSort] = useState(0);

	const { isProductsUpdating, products } = useDesignsByCollections(product_id, sort);

	const isBusy = isProductsUpdating;

	return (
		<div>
			<Loader isBusy={isBusy} />
			<div className="page-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card px-2 py-2">
								<div className="card-body">
									<div>
										<div className="row mb-2">
											<div className="col-md-8">
												<ul className="list-inline pe-none mb-2 ecommerce-sortby-list">
													<li className="list-inline-item">
														<span className="font-weight-medium font-family-secondary">
															Sort by:
														</span>
													</li>
													<li className={`list-inline-item ${sort == 0 ? "active" : ""}`}>
														<a onClick={() => setSort(0)}>Alphabetically A - Z</a>
													</li>
													<li className={`list-inline-item ${sort == 1 ? "active" : ""}`}>
														<a onClick={() => setSort(1)}>Alphabetically Z - A</a>
													</li>
												</ul>
											</div>
											{!isTeam && (
												<div className="col-md-4">
													<div className="form-inline float-md-right">
														<div className="search-box ml-2">
															<div className="position-relative">
																<Link to={`/ejcustomer/${product_id}/addproduct`}>
																	<button
																		type="button"
																		className="btn btn-primary waves-effect waves-light">
																		<i className="ri-add-line align-middle mr-2" /> Add Products
																	</button>
																</Link>
															</div>
														</div>
													</div>
												</div>
											)}
										</div>
										<div className="row no-gutters">
											{products.map((product, index) => (
												<div className="col-xl-3 col-sm-6" key={index} onClick={() => navigate(`/${isTeam ? "ejteam" : "ejcustomer"}/chat/${product.id}`)}>
													<div className="product-box">
														<div className="product-img">
															{/* <div className="product-ribbon badge badge-warning">
															New Updates
														</div> */}
															<img
																src={
																	product.design_file == null || product.design_file == ""
																		? "assets/images/product/img-1.png"
																		: product.design_file
																}
																alt
																className="img-fluid mx-auto d-block"
															/>
														</div>
														<div className="text-center">
															<h5 className="font-size-13 clr">
																<p
																	className="text-dark">
																	{product.name}
																</p>
															</h5>
															{/* <h5 className="mt-3 mb-0">
															<span className="text-muted mr-2">
																<del>$240</del>
															</span>
															$225
														</h5> */}
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
