import React from "react";
import * as Yup from "yup";
import { Loader } from "../../components/Loader";
import { useParams, useNavigate } from "react-router";
import { ErrorMessage, Field, Form, Formik } from "formik";
import {
	useAddProduct,
	useDesigns,
	useProductsByDesignId,
	useRemoveProduct,
	useUpdateProduct,
} from "../../hooks";
import { toast } from "react-toastify";

export const EditDesignPrice = () => {
	const { id } = useParams();
	const navigation = useNavigate();

	const { designs, isDesignsLoading } = useDesigns(id);
	const { isProductsLoading, products } = useProductsByDesignId(id);
	const { addProduct, isAdding } = useAddProduct(id);
	const { deleteProduct, isDeleting } = useRemoveProduct(id);
	const { isUpdating, updateProduct } = useUpdateProduct(id);

	const isBusy = isAdding || isDeleting || isUpdating || isDesignsLoading;

	const validation = Yup.object().shape({
		products: Yup.array().of(
			Yup.object({
				product_name: Yup.string().required("Required"),
				product_price: Yup.number().required("Required"),
			}),
		),
	});

	if (isProductsLoading) return <Loader isBusy={isProductsLoading} />;

	return (
		<Formik
			initialValues={{
				products: products || [{ product_name: "", product_price: "" }],
			}}
			validationSchema={validation}
			onSubmit={async (values) => {
				try {
					await Promise?.all(
						values?.products?.map(async (element) => {
							if (element?.id) return updateProduct({ ...element, product_id: element?.id, id });
							else
								return addProduct({
									design_id: id,
									product_name: element.product_name,
									collection_id: designs.collection_id,
									product_price:
										typeof element.product_price == "string"
											? parseInt(element.product_price)
											: element.product_price,
									product_status: designs.product_status,
									design_category: designs.design_category,
									product_category: designs.product_category,
								});
						}),
					);
					toast.success("Added Prices successfully");
					navigation(-1);
				} catch (error) {
					toast.error("Adding prices failed");
				}
			}}>
			{({ values, setFieldValue, errors, setFieldError, touched, setFieldTouched }) => {
				return (
					<div>
						<Loader isBusy={isBusy} />
						<div className="page-content">
							<div className="container-fluid">
								<div className="row">
									<div className="col-lg-12">
										<div className="card px-2 py-2">
											<div className="card-body">
												<Form>
													<div className="d-flex flex-row justify-content-between mb-3">
														<h4 className="text-truncate mt-3">Update Price</h4>
														<div>
															<button
																type="button"
																className="btn btn-primary waves-effect waves-light"
																onClick={() =>
																	values.products?.length < 5
																		? setFieldValue("products", [
																				...values.products,
																				{ product_name: "", product_price: "" },
																		  ])
																		: toast.error("You have added maximum rows")
																}>
																Add new
															</button>
															<p className="text-truncate small">(can add upto 5)</p>
														</div>
													</div>
													{values.products?.map((prod, index) => (
														<div className="row" key={index}>
															<div className="col-lg-6">
																<div className="form-group mb-4" key={index}>
																	<Field name={`products.${index}.product_name`}>
																		{({ field }) => (
																			<input
																				{...field}
																				type="text"
																				className="form-control"
																				placeholder="Enter Product Name"
																			/>
																		)}
																	</Field>
																</div>
																<ErrorMessage
																	name={`products.${index}.product_name`}
																	component="p"
																	className="error"
																/>
															</div>
															<div className="col-lg-5">
																<div className="form-group mb-4">
																	<div className="input-group mb-3">
																		<div className="input-group-prepend">
																			<span className="input-group-text">$</span>
																		</div>
																		<Field name={`products.${index}.product_price`}>
																			{({ field }) => (
																				<input
																					{...field}
																					type="text"
																					className="form-control"
																					aria-label="Amount (to the nearest dollar)"
																				/>
																			)}
																		</Field>

																		<div className="input-group-append">
																			{/* <span className="input-group-text">.00</span> */}
																		</div>
																	</div>
																	<ErrorMessage
																		name={`products.${index}.product_price`}
																		component="p"
																		className="error"
																	/>
																</div>
															</div>
															<div className="col-lg-1">
																<button
																	type="button"
																	className="btn btn-danger btn-small waves-effect waves-light"
																	onClick={() => {
																		if (prod?.id) deleteProduct(prod?.id);
																		const productsList = [...values?.products];
																		productsList?.splice(index, 1);
																		setFieldValue("products", productsList);
																		if (errors?.products) {
																			const productsErrorsList = [...errors?.products];
																			productsErrorsList?.splice(index, 1);
																			setFieldError("products", productsErrorsList);
																		}
																		if (touched?.products) {
																			const productsTouchedList = [...touched?.products];
																			productsTouchedList?.splice(index, 1);
																			setFieldTouched("products", productsTouchedList);
																		}
																	}}>
																	X
																</button>
															</div>
														</div>
													))}

													<button
														type="submit"
														className="btn btn-primary waves-effect waves-light">
														Save
													</button>
													<button
														type="button"
														className="btn btn-secondary waves-effect waves-light"
														onClick={() => navigation(-1)}>
														Cancel
													</button>
												</Form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}}
		</Formik>
	);
};
