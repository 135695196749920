import { api } from "../index";

export const getCustomers = () =>
	api
		.get("auth/users?user_role=2&offset=0&limit=20")
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const getUserById = (id) =>
	api
		.get(`auth/users/${id}`)
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const removeCustomer = (id) =>
	api
		.delete(`auth/users/${id}`)
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});
