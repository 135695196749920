import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import md5 from "md5";
import { useSignup, useUpdateUser, useUserById } from "../../../hooks";
import { toast } from "react-toastify";
import { getBadRequestErrorMessage } from "../../../api";
import { Loader } from "../../../components/Loader";
import moment from "moment";

export const AddEditTeamMember = () => {
	const { id } = useParams();
	const navigation = useNavigate();
	const { userInfo, isLoading } = useUserById(id);
	const { isUpdating, update } = useUpdateUser(id);
	const { isCreating, userSignup } = useSignup();

	const isBusy = isCreating || isUpdating;

	const validation = Yup.object().shape({
		userName: Yup.string().required("Required"),
		userEmail: Yup.string().email("Invalid email").required("Required"),
		companyName: Yup.string(),
		designation: Yup.string(),
		joiningDate: Yup.string(),
		address: Yup.string(),
		MobileNumber: Yup.number().typeError("Number only"),
		password: Yup.string(),
	});

	if (isLoading) return <Loader isBusy={isLoading} />;

	return (
		<Formik
			initialValues={{
				id,
				isActive: 1,
				userRole: 1,
				certificate: null,
				userName: userInfo?.userName || "",
				userEmail: userInfo?.userEmail || "",
				companyName: id ? userInfo?.companyName || "" : "EJ",
				designation: userInfo?.designation || "",
				joiningDate: id
					? userInfo?.joiningDate && userInfo?.joiningDate == "null"
						? ""
						: userInfo?.joiningDate || ""
					: moment().format("dd-mm-yy"),
				address: userInfo?.address || "",
				MobileNumber:
					userInfo?.MobileNumber && userInfo?.MobileNumber != null
						? userInfo?.MobileNumber
						: "" || "",
				password: "",
			}}
			validationSchema={validation}
			onSubmit={async (values) => {
				try {
					if (id)
						await update({
							...values,
							MobileNumber: values.MobileNumber ? values.MobileNumber?.toString() : "",
							password: md5(values.password),
						});
					else userSignup({ ...values, password: md5(values.password) });
					toast.success("Updated Successfully");
					navigation(-1);
				} catch (error) {
					toast.error(getBadRequestErrorMessage(error));
				}
			}}>
			<div>
				<Loader isBusy={isBusy} />
				<div className="page-content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<div className="card px-2 py-2">
									<div className="card-body">
										<Form>
											<div className="row">
												<div className="col-lg-6">
													<div>
														<div className="form-group mb-4">
															<label htmlFor="input-date1">Name</label>
															<Field name="userName">
																{({ field }) => (
																	<input
																		{...field}
																		className="form-control"
																		type="text"
																		defaultValue={userInfo?.userName}
																		id="example-text-input"
																	/>
																)}
															</Field>
															<ErrorMessage name="userName" component="p" className="error" />
														</div>
														<div className="form-group mb-4">
															<label htmlFor="input-date2">Phones Number</label>
															<Field name="MobileNumber">
																{({ field }) => (
																	<input
																		{...field}
																		className="form-control"
																		type="number"
																		defaultValue={
																			userInfo?.MobileNumber && userInfo?.MobileNumber != null
																				? Number?.(userInfo?.MobileNumber)
																				: ""
																		}
																		id="tele"
																	/>
																)}
															</Field>
															<ErrorMessage name="MobileNumber" component="p" className="error" />
														</div>
														<div className="form-group mb-4">
															<label htmlFor="input-date2">Password</label>
															<Field name="password">
																{({ field }) => (
																	<input
																		{...field}
																		className="form-control"
																		type="password"
																		defaultValue=""
																		id="pwd"
																	/>
																)}
															</Field>
															<ErrorMessage name="password" component="p" className="error" />
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="mt-4 mt-lg-0">
														<div className="form-group mb-4">
															<label htmlFor="input-date2">Email</label>
															<Field name="userEmail">
																{({ field }) => (
																	<input
																		{...field}
																		className="form-control"
																		type="email"
																		defaultValue={userInfo?.userEmail}
																		id="example-email-input"
																	/>
																)}
															</Field>
															<ErrorMessage name="userEmail" component="p" className="error" />
														</div>
														{/* <div className="form-group mb-4">
                                                                <label htmlFor="input-date2">User Status</label>
                                                                <select className="form-control" onChange={(e) => { setUserDetails({ ...UserDetails, isActive: e.target.value == "Active" ? 1 : 0 }); console.log("e.target.value", e.target.value) }}>
                                                                    <option>Active</option>
                                                                    <option>Deactive</option>
                                                                </select>
                                                            </div> */}
														{/* <div className="form-group mb-4">
                                                                <label htmlFor="input-date2">Admin Role</label>
                                                                <select className="form-control" onChange={(e) => { setUserDetails({ ...UserDetails, designation: e.target.value }) }}>
                                                                    <option>Manager</option>
                                                                    <option>Admin</option>
                                                                    <option>Super Admin</option>
                                                                </select>
                                                            </div> */}
													</div>
												</div>
											</div>
											<button type="submit" className="btn btn-primary waves-effect waves-light">
												Save
											</button>
											<button
												type="button"
												className="btn btn-secondary waves-effect waves-light"
												onClick={() => navigation(-1)}>
												Cancel
											</button>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Formik>
	);
};
