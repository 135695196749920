import React, { useMemo, useState } from "react";

export const FinalProductComponent = (props) => {
	const { cart, product, addProductToCart, removeProductFromCart } = {
		...props,
	};
	const [selectedProduct, setSelectedProduct] = useState(product?.products?.[0]);

	const quantity = useMemo(() => {
		return cart?.find((data) => data?.product_id == selectedProduct?.id)?.product_qty || 0;
	}, [cart, selectedProduct]);

	return (
		<div className="col-xl-3 col-sm-6">
			<div className="product-box">
				<div className="product-img">
					<img
						src={
							selectedProduct?.design_file && selectedProduct?.design_file != "null"
								? selectedProduct.design_file
								: "assets/images/product/img-1.png"
						}
						className="img-fluid mx-auto d-block"
					/>
				</div>
				<div className="text-center">
					<h5 className="font-size-13 clr">
						<a className="text-dark">{product.design_name}</a>
					</h5>
					{product?.products?.length > 0 ? (
						<>
							<div className="form-group row proDetaisl mt-2 mb-0">
								<div className="col-md-7">
									<select
										className="form-control"
										onChange={(e) =>
											setSelectedProduct(product?.products?.[e.target.value] || null)
										}>
										{product?.products?.map((p, index) => (
											<option value={index} key={index}>
												{p.product_name}
											</option>
										))}
									</select>
									<h5 className="mt-2 mb-0">
										<span className="text-muted mr-2">Quantity: {quantity}(s)</span>
									</h5>
								</div>
								<h5 className="mt-2 mb-0 col-md-5">
									<span className="text-muted mr-2">
										Price: ${selectedProduct?.product_price?.toFixed(2) || 0}
									</span>
								</h5>
							</div>
							<button
								type="button"
								className="btn btn-warning btn-block mt-4"
								onClick={() =>
									addProductToCart({
										product_id: selectedProduct?.id,
										qty: quantity ? quantity + 1 : 1,
									})
								}>
								Add to Cart (1x)
							</button>
							{quantity > 0 ? (
								<button
									type="button"
									className="btn btn-danger btn-block"
									onClick={() => removeProductFromCart(selectedProduct?.id)}>
									Remove All
								</button>
							) : null}
						</>
					) : (
						<h5 className="mt-2 mb-0">
							<span className="text-muted mr-2">Design&apos;s yet to be added.</span>
						</h5>
					)}
				</div>
			</div>
		</div>
	);
};
