import { createSlice } from "@reduxjs/toolkit";

const initialAuthState = {
	isAuthenticated: false,
	user: null,
};

const slice = createSlice({
	name: "auth",
	initialState: initialAuthState,
	reducers: {
		userLoggedin: (state, { payload }) => {
			return {
				...state,
				isAuthenticated: true,
				user: payload,
			};
		},
		userLoginFailed: (state) => {
			return { ...state, user: undefined, isAuthenticated: false };
		},
		userLoggedout: (state) => {
			return { ...state, user: undefined, isAuthenticated: false };
		},
	},
});

export default slice.reducer;

export const AuthStore = (store) => store.auth;

export const { userLoggedin, userLoginFailed, userLoggedout } = slice.actions;
