import React from "react";
import Team from "./screens/teams/ourteam";
import { Routes, Route, Navigate } from "react-router-dom";
import { Communication as TeamCommunication } from "./screens/teams/communication";
import { Customers as TeamCustomers } from "./screens/teams/customers";
import { Dashboard as TeamDashboard } from "./screens/teams/dashboard/Dashboard";
import { EditCustomer } from "./screens/teams/customers/EditCustomer";
import { EditDesignDetails } from "./screens/collections/EditDesignDetails";
import { AddEditTeamMember } from "./screens/teams/ourteam/AddEditTeamMember";
import { Login } from "./screens/auth/Login";
import { SignUp } from "./screens/auth/SignUp";
import { ForgotPassword } from "./screens/auth/ForgotPassword";
import { ResetPassword } from "./screens/auth/ResetPassword";
import { Updates } from "./screens/updates";
import { Collections } from "./screens/collections";
import { Orders } from "./screens/orders";
import { Products } from "./screens/collections/Products";
import { AddProduct as CustomerAddProduct } from "./screens/collections/AddProduct";
import { Chat } from "./screens/collections/Chat";
import { AccountSettings } from "./screens/customer/account";
import { PlaceOrder } from "./screens/customer/purchase";
import { Cart } from "./screens/customer/cart";
import { Checkout } from "./Screens/Customer/cart/Checkout";
import { Invoice } from "./screens/orders/Invoice";
import { EmailVerification } from "./screens/auth/EmailVerification";
import { useAppSelector } from "./redux";
import { EditDesignPrice } from "./screens/collections/EditDesignPrice";

export const Router = () => {
	const { isAuthenticated } = useAppSelector((state) => state.auth);

	return (
		<Routes>
			{/* ejcustomer */}
			<Route path="ejcustomer">
				{isAuthenticated ? (
					<>
						<Route path={"updates"} element={<Updates />} />
						<Route path={"collections"} element={<Collections />} />
						<Route path={"orders"} element={<Orders />} />
						<Route path={":id/products"} element={<Products />} />
						<Route path={":id/addproduct"} element={<CustomerAddProduct />} />
						<Route path={"accountsettings"} element={<AccountSettings />} />
						<Route path={"chat"}>
							<Route path={":id"} element={<Chat />} />
						</Route>
						<Route path={"placeorder"} element={<PlaceOrder />} />
						<Route path={"cart"} element={<Cart />} />
						<Route path={"checkout"} element={<Checkout />} />
						<Route path={"emailverification"} element={<EmailVerification />} />
						<Route path={"invoice"}>
							<Route path={":id"} element={<Invoice />} />
						</Route>
					</>
				) : null}
				<Route index path={"login"} element={<Login />} />
				<Route path={"signup"} element={<SignUp />} />
				<Route path={"forgotpassword"} element={<ForgotPassword />} />
				<Route path={"resetpassword"} element={<ResetPassword />} />
			</Route>
			{/* ejteam */}
			<Route path="ejteam">
				{isAuthenticated ? (
					<>
						<Route path={"updates"} element={<Updates />} />
						<Route path={"dashboard"} element={<TeamDashboard />} />
						<Route path={"customers"} element={<TeamCustomers />} />
						<Route path={"orders"} element={<Orders />} />
						<Route path={"team"} element={<Team />} />
						<Route path={"communication"} element={<TeamCommunication />} />
						<Route path={"addteammember"} element={<AddEditTeamMember />} />
						<Route path={"editcustomer"}>
							<Route path={":id"} element={<EditCustomer />} />
						</Route>
						<Route path={"collections"}>
							<Route path={":id"} element={<Collections />} />
						</Route>
						<Route path={":id/products"} element={<Products />} />
						<Route path={"chat"}>
							<Route path={":id"} element={<Chat />} />
						</Route>
						<Route path={"chatupdates"}>
							<Route path={":id"} element={<Updates />} />
						</Route>
						<Route path={"editdesign"}>
							<Route path={":id"} element={<EditDesignDetails />} />
						</Route>
						<Route path={"editprice"}>
							<Route path={":id"} element={<EditDesignPrice />} />
						</Route>
						<Route path={"editteammember"}>
							<Route path={":id"} element={<AddEditTeamMember />} />
						</Route>
					</>
				) : null}
				<Route path={"login"} element={<Login />} />
			</Route>
			{/* default */}
			<Route path="/" element={<Navigate to={"/ejcustomer/login"} replace />} />
			<Route path="*" element={<PageNotFound />} />
		</Routes>
	);
};

const PageNotFound = () => {
	return <div>Page not found</div>;
};
