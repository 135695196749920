import moment from "moment";
import React from "react";

const LinkToFile = ({ children, url }) => {
	if (url && url != "")
		return (
			<a href={url} target="_blank" rel="noreferrer">
				{children}
			</a>
		);
	return <>{children}</>;
};

export const FilesModal = ({ show, hide, files, currentUserId }) => {
	return (
		<div
			className="modal fade bs-example-modal-center show custom-modal-bg"
			tabIndex="-1"
			role="dialog"
			aria-labelledby="mySmallModalLabel"
			aria-modal="true"
			style={{ display: show ? "block" : "none" }}>
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title mt-0">Files</h5>
						<button
							type="button"
							className="close"
							data-dismiss="modal"
							aria-label="Close"
							onClick={hide}>
							<span aria-hidden="true">×</span>
						</button>
					</div>
					<div className="modal-body">
						<div className="px-lg-2">
							<div className="chat-conversation p-3">
								<ul
									className="list-unstyled mb-0 pr-3"
									data-simplebar
									style={{ height: 350, overflow: "scroll" }}>
									{files?.map((message, index) => {
										if (message?.from_user_id == currentUserId) {
											return (
												<li className="right" key={index}>
													<div className="conversation-list">
														<div className="ctext-wrap">
															<div className="conversation-name">{message.from_user}</div>
															{(!message.message || message.message == "null") && (
																<LinkToFile url={message.attachment_url}>
																	<div className="ctext-wrap-content">
																		<p className="mb-0">
																			<i
																				className="mdi mdi-attachment lg-2"
																				style={{ marginRight: "5px" }}
																			/>
																			{message.attachment_name}
																		</p>
																	</div>
																</LinkToFile>
															)}
															{message.message && message.message != "null" && (
																<div className="ctext-wrap-content">
																	<p className="mb-0">{message.message}</p>
																</div>
															)}
															<p className="chat-time mb-0">
																<i className="bx bx-time-five align-middle mr-1" />
																{moment(message.message_time).format("hh:mm a")}
															</p>
														</div>
													</div>
												</li>
											);
										} else {
											return (
												<li key={index}>
													<div className="conversation-list">
														<div className="ctext-wrap">
															<div className="conversation-name">{message.from_user}</div>
															{(!message.message || message.message == "null") && (
																<LinkToFile url={message.attachment_url}>
																	<div className="ctext-wrap-content">
																		<p className="mb-0">
																			<i
																				className="mdi mdi-attachment lg-2"
																				style={{ marginRight: "5px" }}
																			/>
																			{message.attachment_name}
																		</p>
																	</div>
																</LinkToFile>
															)}
															{message.message && message.message != "null" && (
																<div className="ctext-wrap-content">
																	<p className="mb-0">{message.message}</p>
																</div>
															)}
															<p className="chat-time mb-0">
																<i className="mdi mdi-clock-outline align-middle mr-1" />
																{moment(message.message_time).format("hh:mm a")}
															</p>
														</div>
													</div>
												</li>
											);
										}
									})}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
