import React, { useState } from "react";
import { FinalProductComponent } from "./components/FinalProductComponent";
import { useAddToCart, useCart, useProducts, useRemoveFromCart } from "../../../hooks";
import { Loader } from "../../../components/Loader";

export const PlaceOrder = () => {
	const [sort, setSort] = useState(0);

	const { cart, isLoading } = useCart();
	const { products, isProductsLoading } = useProducts(sort);
	const { addToCart, isAdding } = useAddToCart();
	const { isRemoving, removeFromCart } = useRemoveFromCart();

	const isBusy = isLoading || isProductsLoading || isAdding || isRemoving;

	return (
		<div>
			<Loader isBusy={isBusy} />
			<div className="page-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card px-2 py-2">
								<div className="card-body">
									<div>
										<div className="row mb-2">
											<div className="col-md-8">
												<ul className="list-inline pe-none mb-2 ecommerce-sortby-list">
													<li className="list-inline-item">
														<span className="font-weight-medium font-family-secondary">
															Sort by:
														</span>
													</li>
													<li
														className={sort == 0 ? "list-inline-item active" : "list-inline-item"}>
														<a onClick={() => setSort(0)}>Alphabetically A - Z</a>
													</li>
													<li
														className={sort == 1 ? "list-inline-item active" : "list-inline-item"}>
														<a onClick={() => setSort(1)}>Alphabetically Z - A</a>
													</li>
												</ul>
											</div>
											<div className="col-md-4"></div>
										</div>
										<div className="row no-gutters">
											{products.map((product, index) => (
												<FinalProductComponent
													key={index}
													cart={cart}
													product={product}
													addProductToCart={addToCart}
													removeProductFromCart={removeFromCart}
												/>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
