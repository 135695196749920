import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Loader } from "../../../components/Loader";
import { useAllCommunications } from "../../../hooks";

export const Communication = () => {
	const { communications, isLoading } = useAllCommunications();

	const isBusy = isLoading;

	return (
		<div>
			<Loader isBusy={isBusy} />
			<div className="page-content">
				<div className="container-fluid">
					{/* <div className="row mb-2">
						<div className="col-lg-3">
							<input
								className="form-control"
								type="week"
								defaultValue="2020-W14"
								id="example-week-input"
							/>
						</div>
						<div className="col-lg-3">
							<select className="form-control">
								<option>Country</option>
								<option>India</option>
								<option>US</option>
								<option>UK</option>
								<option>Australia</option>
							</select>
						</div>
						<div className="col-lg-3">
							<form>
								<div className="search-box">
									<div className="position-relative">
										<input
											type="text"
											className="form-control rounded"
											placeholder="Search Name / Company"
										/>
										<i className="mdi mdi-magnify search-icon" />
									</div>
								</div>
							</form>
						</div>
					</div> */}
					<div className="row">
						<div className="col-lg-12">
							<div className="card px-2 py-2">
								<div className="card-body  pt-0">
									<div className="table-responsive">
										<table
											className="table table-centered datatable dt-responsive nowrap "
											style={{
												borderCollapse: "collapse",
												borderSpacing: 0,
												width: "100%",
											}}>
											<thead className="thead-light">
												<tr>
													{/* <th>Order ID</th> */}
													<th>Name</th>
													<th>Brand</th>
													<th>Email ID</th>
													<th>Last Activity</th>
													<th style={{ width: 120 }}>Action</th>
												</tr>
											</thead>
											<tbody>
												{communications?.map((collection, index) => (
													<tr key={index}>
														<td>{collection.user_name}</td>
														<td>{collection.collectionName}</td>
														<td>{collection.user_email}</td>
														<td>
															{moment(
																collection.last_updated == null
																	? collection.createdDate
																	: collection.last_updated,
															).format("DD MMM, YYYY")}
														</td>
														<td>
															<div style={{ display: "flex" }}>
																<Link
																	to={`/ejteam/chatupdates/${collection.userId}`}
																	className="mr-3 text-primary"
																	data-toggle="tooltip"
																	data-placement="top"
																	title
																	data-original-title="View">
																	<button className="btn" type="submit" style={{ marginLeft: -10 }}>
																		<img
																			src="https://icons.iconarchive.com/icons/papirus-team/papirus-apps/512/preferences-desktop-notification-bell-icon.png"
																			alt=""
																			style={{
																				width: "18px",
																				height: "18px",
																				marginTop: -2,
																				marginRight: -15,
																			}}
																		/>
																	</button>
																</Link>
																<Link
																	to={`/ejteam/collections/${collection.userId}`}
																	className="mr-3 text-primary"
																	data-toggle="tooltip"
																	data-placement="top"
																	title
																	data-original-title="View">
																	<button className="btn btn-primary" type="submit">
																		View
																	</button>
																</Link>
															</div>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
