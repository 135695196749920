import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import auth from "./auth";

const rootReducer = combineReducers({
	auth,
});

const store = configureStore({
	reducer: rootReducer,
});

export const useAppDispatch = useDispatch;
export const useAppSelector = useSelector;

export default store;
