import React, { useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddToCart, useCart, useRemoveFromCart } from "../../../hooks";
import CartComponent from "../../../Screens/Customer/cart/components/CartComponent";
import { Loader } from "../../../components/Loader";

export const Cart = () => {
	const navigation = useNavigate();
	const { cart, isLoading } = useCart();
	const { addToCart, isAdding } = useAddToCart();
	const { removeFromCart, isRemoving } = useRemoveFromCart();
	console.log({cart});

	const discount = useMemo(() => 0, [cart]);
	const subtotal = useMemo(() => {
		cart?.reduce((a, b) => a + (b?.product_price || 0) * (b?.product_qty || 0), 0);
		return cart?.reduce((a, b) => a + b?.product_price * b?.product_qty, 0);
	}, [cart]);
	const shipping = useMemo(() => 0, [cart]);
	const total = useMemo(() => {
		return subtotal - discount + shipping;
	}, [cart]);

	const isBusy = isLoading || isAdding || isRemoving;

	return (
		<div>
			<Loader isBusy={isBusy} />
			<div className="page-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card px-2 py-2">
								<div className="card-body">
									{cart?.length > 0 ? (
										<div className="table-responsive">
											<table className="custom-table table table-centered mb-0 table-nowrap">
												<thead className="bg-light">
													<tr>
														<th className="w-20">Product</th>
														<th className="w-25">Product Description</th>
														<th>Price</th>
														<th>Quantity</th>
														<th>Total</th>
														<th className="text-center">Action</th>
													</tr>
												</thead>
												<tbody>
													{cart?.map((product, index) => (
														<CartComponent
															key={index}
															product={product}
															addToCart={addToCart}
															removeFromCart={removeFromCart}
														/>
													))}

													<tr className="bg-light text-right">
														<th scope="row" colSpan={5}>
															Sub Total :
														</th>
														<td>$ {subtotal}</td>
													</tr>
													{discount > 0 ? (
														<tr className="bg-light text-right">
															<th scope="row" colSpan={5}>
																Discount :
															</th>
															<td>- ${discount}</td>
														</tr>
													) : null}
													{shipping > 0 ? (
														<tr className="bg-light text-right">
															<th scope="row" colSpan={5}>
																Shipping Charge :
															</th>
															<td>$ {shipping}</td>
														</tr>
													) : null}
													<tr className="bg-light text-right">
														<th scope="row" colSpan={5}>
															Total :
														</th>
														<td>$ {total}</td>
													</tr>
												</tbody>
											</table>
											<div className="mt-4 text-right">
												<Link to={"/ejcustomer/checkout"} className="btn btn-primary">
													Place order
												</Link>
											</div>
										</div>
									) : (
										<div className="d-flex flex-column my-5 justify-content-center align-items-center">
											<h3 className="font-size-18 text-truncate">Your cart is currently empty</h3>
											<h5 className="font-size-14 text-truncate my-2">
												Please add product to place the order
											</h5>
											<button
												type="button"
												className="btn btn-warning my-4"
												onClick={() => navigation("/")}>
												Home
											</button>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
