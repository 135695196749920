import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { queryClient } from "./api/client";
import {
	userSignup,
	emailVerification,
	forgotEmail,
	login,
	resetPassword,
	sendOTP,
	updateUser,
} from "./api/resources/admin";
import {
	addProduct,
	createCollection,
	getChat,
	getCollections,
	getDesigns,
	getDesignsByCollections,
	getProductByDesignId,
	removeProduct,
	sendMessage,
	updateProduct,
	uploadDesignsCustomer,
	uploadDesignsTeam,
} from "./api/resources/collections";
import { getAllCommunication } from "./api/resources/communication";
import { getUserById, getCustomers, removeCustomer } from "./api/resources/customers";
import { getDashboard } from "./api/resources/dashboard";
import { getInvoice, getOrders } from "./api/resources/orders";
import { getTeamMembers } from "./api/resources/ourteam";
import {
	addToCart,
	getCart,
	getProducts,
	placeOrder,
	removeFromCart,
} from "./api/resources/placeOrder";
import { getUpdates, readNotifications } from "./api/resources/updates";

//admin

export const useLogin = () => {
	const { mutateAsync, isLoading } = useMutation((data) => login(data));
	return {
		login: mutateAsync,
		isLogging: isLoading,
	};
};

export const useSignup = () => {
	const { mutateAsync, isLoading } = useMutation((data) => userSignup(data));
	return {
		userSignup: mutateAsync,
		isCreating: isLoading,
	};
};

export const useSendOtp = ({ email, onSuccess, onError }) => {
	const isEnabled = !!email;
	const { isLoading, isFetching, refetch } = useQuery(
		["sendOTP", email],
		() => sendOTP({ email }),
		{
			enabled: isEnabled,
			onSuccess: (data) => onSuccess && onSuccess(data),
			onError: (error) => onError && onError(error),
		},
	);
	return {
		refetchOTP: refetch,
		otpLoading: isLoading || isFetching,
	};
};

export const useCustomerEmailVerification = () => {
	const { mutateAsync, isLoading } = useMutation((data) => emailVerification(data));
	return {
		verifyOtp: mutateAsync,
		isVerifying: isLoading,
	};
};

export const useForgotUserInfo = () => {
	const { mutateAsync, isLoading } = useMutation((data) => forgotEmail(data));
	return {
		forgotInfo: mutateAsync,
		isLoading: isLoading,
	};
};

export const useResetPassword = () => {
	const { mutateAsync, isLoading } = useMutation((data) => resetPassword(data));
	return {
		resetPassword: mutateAsync,
		isResetting: isLoading,
	};
};

export const useUpdateUser = (id) => {
	const { mutateAsync, isLoading } = useMutation((data) => updateUser(id, data));
	return {
		update: mutateAsync,
		isUpdating: isLoading,
	};
};

//updates

const getUpdatesCache = (id) => ["notifications", id];

export const useUpdates = ({ userId, enabled, filter_id }) => {
	const { data, isLoading, isFetching, refetch } = useQuery(
		getUpdatesCache(userId),
		() => getUpdates(userId),
		{ enabled: enabled },
	);

	const filteredUpdates = useMemo(() => {
		if (filter_id) {
			return data?.filter((update) => update.owner_id == filter_id);
		} else return data;
	}, [filter_id, data]);

	return {
		updates: filteredUpdates || [],
		refetchUpdates: refetch,
		isUpdatesLoading: isLoading || isFetching,
	};
};

export const useReadNotifications = (userId) => {
	const { mutateAsync, isLoading } = useMutation((id) => readNotifications(id), {
		onSuccess: () => queryClient.invalidateQueries(getUpdatesCache(userId)),
	});
	return {
		readNotification: mutateAsync,
		isReading: isLoading,
	};
};

//collections

const getCollectionCackeKey = (id) => ["collections", id];

export const useCollections = (sort, id, enabled) => {
	const { data, isLoading, isFetching, refetch } = useQuery(
		getCollectionCackeKey(id),
		() => getCollections(id),
		{ enabled: enabled },
	);
	const collectionList = useMemo(() => {
		if (sort == 0)
			return data?.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate)) || [];
		else if (sort == 1)
			return data?.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate)) || [];
		else if (sort == 2)
			return (
				data?.sort((a, b) =>
					a.collectionName < b.collectionName ? -1 : a.collectionName > b.collectionName ? 1 : 0,
				) || []
			);
		else if (sort == 3)
			return (
				data?.sort((a, b) =>
					b.collectionName < a.collectionName ? -1 : b.collectionName > a.collectionName ? 1 : 0,
				) || []
			);
		else return [];
	}, [sort, data]);

	return {
		collections: collectionList || [],
		refetchCollections: refetch,
		isCollectionsLoading: isLoading || isFetching,
	};
};

export const useCreateCollection = (onSuccess) => {
	const { mutateAsync, isLoading } = useMutation(({ name, id }) => createCollection(name, id), {
		onSuccess: () => {
			queryClient.invalidateQueries(["collections", null]);
			onSuccess && onSuccess();
		},
	});
	return {
		createCollection: mutateAsync,
		isCreating: isLoading,
	};
};

const getDesignsByCollectionCacheKey = (id) => ["designs/bycollections", id];

export const useDesignsByCollections = (id, sort) => {
	const isEnabled = !!id;
	const { data, isLoading, isFetching, refetch } = useQuery(
		getDesignsByCollectionCacheKey(id),
		() => getDesignsByCollections(id),
		{ enabled: isEnabled },
	);
	const productsList = useMemo(() => {
		if (sort == 0)
			return data?.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0)) || [];
		else if (sort == 1)
			return data?.sort((a, b) => (b.name < a.name ? -1 : b.name > a.name ? 1 : 0)) || [];
		else return [];
	}, [sort, data]);

	return {
		products: productsList || [],
		refetchProducts: refetch,
		isProductsUpdating: isLoading || isFetching,
	};
};

export const useCreateUpdateDesignsCustomer = (id) => {
	const { mutateAsync, isLoading } = useMutation((data) => uploadDesignsCustomer(data), {
		onSuccess: () => {
			queryClient.invalidateQueries(getDesignsByCollectionCacheKey(id));
		},
	});
	return {
		createUpdateDesign: mutateAsync,
		isCreating: isLoading,
	};
};

export const useCreateUpdateDesignsTeam = (id) => {
	const { mutateAsync, isLoading } = useMutation((data) => uploadDesignsTeam(id, data), {
		onSuccess: () => {
			queryClient.invalidateQueries(getDesignsCacheKey(id));
			queryClient.invalidateQueries(getProductsByDesignIdCacheKey(id));
		},
	});
	return {
		createUpdateDesign: mutateAsync,
		isCreating: isLoading,
	};
};

const getProductsByDesignIdCacheKey = (id) => ["products", id];

export const useProductsByDesignId = (id, enabled) => {
	const { data, isLoading, isFetching } = useQuery(
		getProductsByDesignIdCacheKey(id),
		() => getProductByDesignId(id),
		{
			enabled: enabled,
		},
	);

	return {
		products: data || [],
		isProductsLoading: isLoading || isFetching,
	};
};

export const useRemoveProduct = (id) => {
	const { mutateAsync, isLoading } = useMutation((product_id) => removeProduct(product_id), {
		onSuccess: () => {
			queryClient.invalidateQueries(getProductsByDesignIdCacheKey(id));
		},
	});
	return {
		deleteProduct: mutateAsync,
		isDeleting: isLoading,
	};
};

export const useAddProduct = (id) => {
	const { mutateAsync, isLoading } = useMutation((data) => addProduct(data), {
		onSuccess: () => {
			queryClient.invalidateQueries(getProductsByDesignIdCacheKey(id));
		},
	});
	return {
		addProduct: mutateAsync,
		isAdding: isLoading,
	};
};

export const useUpdateProduct = (id) => {
	const { mutateAsync, isLoading } = useMutation((data) => updateProduct(data), {
		onSuccess: () => {
			queryClient.invalidateQueries(getProductsByDesignIdCacheKey(id));
		},
	});
	return {
		updateProduct: mutateAsync,
		isUpdating: isLoading,
	};
};

//orders

const getOrdersCacheKey = (id) => ["orders", id];

export const useOrders = (id, enabled) => {
	const { data, isLoading, isFetching } = useQuery(getOrdersCacheKey(id), () => getOrders(id), {
		enabled: enabled,
	});

	return {
		orders: data || [],
		isOrdersLoading: isLoading || isFetching,
	};
};

const getInvoiceCacheKey = (id) => ["orders", id];

export const useInvoice = (id) => {
	const isEnabled = !!id;
	const { data, isLoading, isFetching } = useQuery(getInvoiceCacheKey(id), () => getInvoice(id), {
		enabled: isEnabled,
	});

	return {
		invoice: data?.[0],
		isInvoiceLoading: isLoading || isFetching,
	};
};

const getChatCacheKey = (id) => ["chat", id];

export const useChat = (id) => {
	const isEnabled = !!id;
	const { data, isLoading, isFetching } = useQuery(getChatCacheKey(id), () => getChat(id), {
		enabled: isEnabled,
		refetchInterval: 5000,
	});

	return {
		chat: data,
		isChatLoading: isLoading || isFetching,
	};
};

const getDesignsCacheKey = (id) => ["designs", id];

export const useDesigns = (id) => {
	const isEnabled = !!id;
	const { data, isLoading, isFetching } = useQuery(getDesignsCacheKey(id), () => getDesigns(id), {
		enabled: isEnabled,
	});

	return {
		designs: data?.[0],
		isDesignsLoading: isLoading || isFetching,
	};
};

export const useSendMessage = (id) => {
	const { mutateAsync, isLoading } = useMutation((data) => sendMessage(data), {
		onSuccess: () => queryClient.invalidateQueries(getChatCacheKey(id)),
	});
	return {
		send: mutateAsync,
		isSending: isLoading,
	};
};

//place order

export const useCart = () => {
	const { data, isLoading, isFetching } = useQuery(["cart"], () => getCart());

	return {
		cart: data || [],
		isLoading: isLoading || isFetching,
	};
};

export const useProducts = (sort) => {
	const { data, isLoading, isFetching } = useQuery(["products"], () => getProducts());

	const productsList = useMemo(() => {
		if (sort == 0)
			return (
				data?.sort((a, b) =>
					a.design_name < b.design_name ? -1 : a.design_name > b.design_name ? 1 : 0,
				) || []
			);
		else if (sort == 1)
			return (
				data?.sort((a, b) =>
					b.design_name > a.design_name ? -1 : b.design_name < a.design_name ? 1 : 0,
				) || []
			);
		else return [];
	}, [sort, data]);

	return {
		products: productsList || [],
		isProductsLoading: isLoading || isFetching,
	};
};

export const useAddToCart = () => {
	const { mutateAsync, isLoading } = useMutation((data) => addToCart(data), {
		onSuccess: () => queryClient.invalidateQueries(["cart"]),
	});
	return {
		addToCart: mutateAsync,
		isAdding: isLoading,
	};
};

export const useRemoveFromCart = () => {
	const { mutateAsync, isLoading } = useMutation((product_id) => removeFromCart(product_id), {
		onSuccess: () => queryClient.invalidateQueries(["cart"]),
	});
	return {
		removeFromCart: mutateAsync,
		isRemoving: isLoading,
	};
};

export const usePlaceOrder = () => {
	const { mutateAsync, isLoading } = useMutation((data) => placeOrder(data), {
		onSuccess: () => queryClient.invalidateQueries(["cart"]),
	});
	return {
		placeOrder: mutateAsync,
		isPlacing: isLoading,
	};
};

//dashboard

export const useDashboard = () => {
	const { data, isLoading, isFetching } = useQuery(["dashboard"], () => getDashboard());

	return {
		dashboardInfo: data || null,
		isLoading: isLoading || isFetching,
	};
};

//customer

export const useCustomers = () => {
	const { data, isLoading, isFetching } = useQuery(["customers"], () => getCustomers());

	return {
		customers: data || [],
		isLoading: isLoading || isFetching,
	};
};

export const useRemoveCustomer = () => {
	const { mutateAsync, isLoading } = useMutation((id) => removeCustomer(id), {
		onSuccess: () => queryClient.invalidateQueries(["customers"]),
	});
	return {
		remove: mutateAsync,
		isRemoving: isLoading,
	};
};

const getUserByIdCacheKey = (id) => ["user", id];

export const useUserById = (id) => {
	const isEnabled = !!id;
	const { data, isLoading, isFetching } = useQuery(getUserByIdCacheKey(id), () => getUserById(id), {
		enabled: isEnabled,
	});

	return {
		userInfo: data?.[0] || null,
		isLoading: isEnabled ? isLoading || isFetching : false,
	};
};

//our team

export const useTeamMembers = () => {
	const { data, isLoading, isFetching } = useQuery(["teammembers"], () => getTeamMembers());

	return {
		teamMembers: data || [],
		isLoading: isLoading || isFetching,
	};
};

//communication

export const useAllCommunications = () => {
	const { data, isLoading, isFetching } = useQuery(["communication"], () => getAllCommunication());

	return {
		communications: data || [],
		isLoading: isLoading || isFetching,
	};
};
