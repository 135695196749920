import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../redux";
import { AuthStore } from "../redux/auth";

export const NavBar = () => {
	const { user } = useAppSelector(AuthStore);
	const isTeam = user?.userData?.userRole == 1;

	return (
		<div className="topnav">
			<div className="container-fluid">
				<nav className="navbar navbar-light navbar-expand-lg topnav-menu">
					<div className="collapse navbar-collapse" id="topnav-menu-content">
						<ul className="navbar-nav">
							<li className="nav-item">
								<Link className="nav-link" to={`/${isTeam ? "ejteam" : "ejcustomer"}/updates`}>
									<i className="ri-pencil-ruler-2-line mr-2"></i> Updates
								</Link>
							</li>
							{isTeam ? (
								<li className="nav-item1">
									<Link className="nav-link" to="/ejteam/dashboard">
										<i className="ri-dashboard-line mr-2"></i> Dashboard
									</Link>
								</li>
							) : (
								<li className="nav-item1">
									<Link className="nav-link" to={"/ejcustomer/collections"}>
										<i className="ri-stack-line mr-2"></i> Collections &amp; Products
									</Link>
								</li>
							)}
							{isTeam ? (
								<li className="nav-item1">
									<Link className="nav-link" to="/ejteam/customers">
										<i className="ri-user-2-line mr-2"></i> Customers
									</Link>
								</li>
							) : null}
							<li className="nav-item1">
								<Link className="nav-link" to={`/${isTeam ? "ejteam" : "ejcustomer"}/orders`}>
									<i className="ri-dashboard-line mr-2"></i> Orders
								</Link>
							</li>
							{isTeam ? (
								<li className="nav-item1">
									<Link className="nav-link" to="/ejteam/team">
										<i className="ri-team-line mr-2"></i> Our Team
									</Link>
								</li>
							) : (
								<li className="nav-item1">
									<Link className="nav-link" to={"/ejcustomer/accountsettings"}>
										<i className="ri-settings-2-line mr-2"></i> Account Settings
									</Link>
								</li>
							)}
							{isTeam ? (
								<li className="nav-item1">
									<Link className="nav-link" to="/ejteam/communication">
										<i className="ri-message-2-line mr-2"></i> Communication
									</Link>
								</li>
							) : (
								<li className="nav-item1">
									<Link className="nav-link" to={"/ejcustomer/placeorder"}>
										<i className="ri-shopping-cart-2-line mr-2"></i> Place Order
									</Link>
								</li>
							)}
						</ul>
					</div>
				</nav>
			</div>
		</div>
	);
};
