import React from "react";
import md5 from "md5";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import S3 from "react-aws-s3";
import { S3_CONFIG } from "../../components/constants";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useSignup } from "../../hooks";
import { Loader } from "../../components/Loader";
import { toast } from "react-toastify";
import { getBadRequestErrorMessage } from "../../api";

export const SignUp = () => {
	const navigation = useNavigate();

	const { userSignup, isCreating } = useSignup();

	const ReactS3Client = new S3(S3_CONFIG);

	const validation = Yup.object().shape({
		userName: Yup.string().required("Required"),
		userEmail: Yup.string().email("Enter valid email address").required("Required"),
		userRole: Yup.number().required("Required"),
		password: Yup.string().required("Required"),
		companyName: Yup.string(),
		designation: Yup.string(),
		image: Yup.mixed().nullable(),
		path: Yup.string()
			.when("image", {
				is: (image) => !!image,
				then: Yup.string().required("Required").nullable(),
			})
			.nullable(),
	});

	return (
		<Formik
			initialValues={{
				userName: "",
				userEmail: "",
				userRole: 2,
				password: "",
				companyName: "",
				designation: "",
				image: null,
				path: null,
			}}
			validationSchema={validation}
			onSubmit={async (values) => {
				const uploadedImage = values.image
					? await ReactS3Client.uploadFile(values.image, Date.now() + ".jpg")
					: null;
				try {
					await userSignup({
						userName: values.userName,
						userEmail: values.userEmail,
						userRole: 2,
						password: md5(values.password),
						companyName: values.companyName,
						designation: values.userRole,
						userImg: uploadedImage?.location,
						mobileNumber: null,
						address: null,
						joining_date: null,
					});
					toast.success("Signed up successfully");
					navigation(-1);
				} catch (error) {
					console.log(error);
					toast.error(getBadRequestErrorMessage(error));
				}
			}}>
			{({ values, setFieldValue, setFieldTouched, touched, errors }) => {
				return (
					<div>
						<Loader isBusy={isCreating} />
						<div className="home-btn d-none d-sm-block">
							<Link to="/ejcustomer/signup">
								<i className="mdi mdi-home-variant h2 text-white" />
							</Link>
						</div>
						<div>
							<div className="container-fluid p-0">
								<div className="row no-gutters">
									<div className="col-lg-4">
										<div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
											<div className="w-100">
												<div className="row justify-content-center">
													<div className="col-lg-9">
														<div>
															<div className="text-center">
																<h4 className="font-size-18 mt-4">Register account</h4>
																<p className="text-muted">
																	Get your free Essentials Jewelry account now.
																</p>
															</div>
															<div className="p-2 mt-4">
																<Form className="form-horizontal">
																	<div className="form-group auth-form-group-custom mb-2">
																		<i className="ri-user-2-line auti-custom-input-icon" />
																		<label htmlFor="username">Full Name</label>
																		<Field name="userName">
																			{({ field }) => (
																				<input
																					{...field}
																					type="text"
																					className="form-control"
																					id="username"
																					placeholder="Enter Full Name"
																				/>
																			)}
																		</Field>
																		<ErrorMessage name="userName" component="p" className="error" />
																	</div>
																	<div className="form-group auth-form-group-custom mb-2">
																		<i className="ri-mail-line auti-custom-input-icon" />
																		<label htmlFor="useremail">Email</label>
																		<Field name="userEmail">
																			{({ field }) => (
																				<input
																					{...field}
																					type="email"
																					className="form-control"
																					id="useremail"
																					placeholder="Enter email"
																				/>
																			)}
																		</Field>
																		<ErrorMessage
																			name="userEmail"
																			component="p"
																			className="error"
																		/>
																	</div>
																	<div className="form-group auth-form-group-custom mb-2">
																		<i className="ri-lock-2-line auti-custom-input-icon" />
																		<label htmlFor="userpassword">Password</label>
																		<Field name="password">
																			{({ field }) => (
																				<input
																					{...field}
																					type="password"
																					className="form-control"
																					id="userpassword"
																					placeholder="Enter password"
																				/>
																			)}
																		</Field>
																		<ErrorMessage name="password" component="p" className="error" />
																	</div>
																	<div className="form-group auth-form-group-custom mb-2">
																		<i className="ri-user-2-line auti-custom-input-icon" />
																		<label htmlFor="username">Company Name (Optional)</label>
																		<Field name="companyName">
																			{({ field }) => (
																				<input
																					{...field}
																					type="text"
																					className="form-control"
																					id="usercompany"
																					placeholder="Enter Company Name"
																				/>
																			)}
																		</Field>
																		<ErrorMessage
																			name="companyName"
																			component="p"
																			className="error"
																		/>
																	</div>
																	<div className="form-group auth-form-group-custom mb-2">
																		<i className="ri-user-2-line auti-custom-input-icon" />
																		<label htmlFor="username">Designation (Optional)</label>
																		<Field name="designation">
																			{({ field }) => (
																				<input
																					{...field}
																					type="text"
																					className="form-control"
																					id="userdesignation"
																					placeholder="Enter Designation"
																				/>
																			)}
																		</Field>
																		<ErrorMessage
																			name="designation"
																			component="p"
																			className="error"
																		/>
																	</div>
																	<div className="custom-file mb-3">
																		<input
																			type="file"
																			accept="image/*"
																			className="custom-file-input"
																			id="customFile"
																			webkitdirectory
																			onChange={(e) => {
																				setFieldTouched("image");
																				const file = e.target.files[0];
																				const reader = new FileReader();
																				reader.readAsDataURL(file);
																				reader.onloadend = () =>
																					setFieldValue("path", reader.result);
																				setFieldValue("image", e.target.files[0]);
																			}}
																		/>
																		<label
																			className="custom-file-label overflow-hidden"
																			htmlFor="customFile">
																			{!!values.image && values.path
																				? values.image.name
																				: "Brand logo (optional)"}
																		</label>
																		{touched.image && errors.image ? (
																			<p className="error">{errors.image}</p>
																		) : null}
																	</div>
																	<div className="text-center">
																		<p>
																			<button
																				className="btn btn-primary w-md waves-effect waves-light"
																				type="submit">
																				Register
																			</button>
																		</p>
																	</div>
																</Form>
															</div>
															<div className="mt-2 text-center">
																<p>
																	Already have an account ?
																	<Link
																		to="/ejcustomer/login"
																		className="font-weight-medium text-primary">
																		<button
																			className="btn btn-primary w-md waves-effect waves-light"
																			type="submit">
																			Login
																		</button>
																	</Link>
																</p>
																<p>© 2021 Essentials Jewelry.</p>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-lg-8">
										<div className="authentication-bg">{/* <div class="bg-overlay"></div> */}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			}}
		</Formik>
	);
};
