import { api } from "../index";

export const getUpdates = (id) =>
	api
		.get(id ? `notifications/${id}` : "notifications")
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const readNotifications = (id) =>
	api
		.put(`notifications/${id}`)
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});
