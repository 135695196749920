import { api } from "../index";

export const login = (data) =>
	api
		.post("auth/login", JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const sendOTP = (data) =>
	api
		.post("auth/sendOtp", JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const emailVerification = (data) =>
	api
		.post("auth/verifyEmail", JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const forgotEmail = (data) =>
	api
		.post("auth/forgotpassword", JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const resetPassword = (data) =>
	api
		.post("auth/resetpassword", JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const userSignup = (data) =>
	api
		.post("auth/user", JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const updateUser = (id, data) =>
	api
		.post(`auth/users/${id}`, JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			console.log(error);
			throw new Error(error);
		});
