import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { getBadRequestErrorMessage } from "../../api";
import { useForgotUserInfo } from "../../hooks";
import { Loader } from "../../components/Loader";

export const ForgotPassword = () => {
	const navigation = useNavigate();

	const { forgotInfo, isLoading } = useForgotUserInfo();

	const validation = Yup.object().shape({
		email: Yup.string().email("Invalid email").required("Required"),
	});

	return (
		<Formik
			initialValues={{ email: "" }}
			validationSchema={validation}
			onSubmit={async (values) => {
				try {
					await forgotInfo({ email: values.email });
					navigation("/ejcustomer/resetpassword");
				} catch (error) {
					console.log(error);
					toast.error(getBadRequestErrorMessage(error));
				}
			}}>
			<div>
				<Loader isBusy={isLoading} />
				<div className="container-fluid p-0">
					<div className="row no-gutters">
						<div className="col-lg-4">
							<div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
								<div className="w-100">
									<div className="row justify-content-center">
										<div className="col-lg-9">
											<div>
												<div className="text-center">
													<div>
														<a href="/" className="logo">
															<img src="assets/images/logo-dark.png" height={30} alt="logo" />
														</a>
													</div>
													<h4 className="font-size-18 mt-4">Reset Password</h4>
													<p className="text-muted">Reset your password to Essentials Jewelry.</p>
												</div>
												<div className="p-2 mt-5">
													<div className="alert alert-success mb-4" role="alert">
														Enter your Email and instructions will be sent to you!
													</div>
													<Form className="form-horizontal">
														<div className="form-group auth-form-group-custom mb-4">
															<i className="ri-mail-line auti-custom-input-icon" />
															<label htmlFor="useremail">Email</label>
															<Field name="email">
																{({ field }) => (
																	<input
																		type="email"
																		className="form-control"
																		id="useremail"
																		placeholder="Enter email"
																		{...field}
																	/>
																)}
															</Field>
															<ErrorMessage name="email" component="p" className="error" />
														</div>
														<div className="mt-4 text-center">
															<button
																className="btn btn-primary w-md waves-effect waves-light"
																type="submit">
																Reset
															</button>
														</div>
													</Form>
												</div>
												<div className="mt-5 text-center">
													<p>
														Don&apos;t have an account ?
														<Link to="/" className="font-weight-medium text-primary">
															<button
																className="btn btn-primary w-md waves-effect waves-light"
																type="button">
																Log in
															</button>
														</Link>
													</p>
													<p>© 2021 Essentials Jewelry.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="authentication-bg">{/* <div class="bg-overlay"></div> */}</div>
						</div>
					</div>
				</div>
			</div>
		</Formik>
	);
};
