import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import S3 from "react-aws-s3";
import { S3_CONFIG } from "../../components/constants";
import { useCreateUpdateDesignsCustomer } from "../../hooks";
import { Loader } from "../../components/Loader";
import { toast } from "react-toastify";
import { getBadRequestErrorMessage } from "../../api";

export const AddProduct = () => {
	const navigation = useNavigate();
	const { id: product_id } = useParams();

	const ReactS3Client = new S3(S3_CONFIG);

	const { createUpdateDesign, isCreating } = useCreateUpdateDesignsCustomer(product_id);

	const validation = Yup.object().shape({
		products: Yup.array().of(
			Yup.object().shape({
				name: Yup.string().required("Required"),
				image: Yup.mixed().required("Required").nullable(),
				path: Yup.string().required("Required").nullable(),
				description: Yup.string().required("Required"),
			}),
		),
	});

	const formik = useFormik({
		initialValues: {
			products: [
				{
					name: "",
					description: "",
					image: null,
					path: null,
				},
			],
		},
		validationSchema: validation,
		onSubmit: async (values) => {
			Promise.all(
				values.products?.map(async (data) => {
					const uploadImage = await ReactS3Client.uploadFile(
						data.image,
						Date.now() + "." + data.image["type"].split("/"),
					);
					createUpdateDesign({
						collectionId: product_id,
						name: data.name,
						description: data.description,
						designFile: uploadImage?.location,
					}).catch((error) => toast.error(getBadRequestErrorMessage(error)));
				}),
			).then(() => {
				toast.success("Collection added successfully");
				navigation(-1);
			});
		},
	});

	const duplicateHandler = (product) => {
		const prevData = [...formik.values.products];
		prevData?.push({
			id: Math.floor(1000 + Math.random() * 9000),
			...product,
		});
		formik.setFieldValue("products", prevData);
	};

	return (
		<div>
			<Loader isBusy={isCreating} />
			<div className="page-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card px-2 py-2">
								<div className="card-body">
									{formik.values.products.map((product, index) => (
										<div key={index}>
											<div className="row">
												<div className="col-lg-3">
													<div className="rounded">
														<img
															src={
																!!formik.values.products?.[index]?.image &&
																formik.values.products?.[index]?.path
																	? formik.values.products?.[index]?.path
																	: "assets/images/collection/collection2.jpg"
															}
															className="w-100  rounded"
															alt="chat-user"
														/>
														<div className="custom-file mt-3">
															<input
																type="file"
																accept="image/*"
																className="custom-file-input"
																id="validatedCustomFile"
																required
																webkitdirectory
																onBlur={() => formik.setFieldTouched(`products.[${index}].image`)}
																onChange={(e) => {
																	const file = e.target.files[0];
																	const reader = new FileReader();
																	reader.readAsDataURL(file);
																	reader.onloadend = () =>
																		formik.setFieldValue(`products.[${index}].path`, reader.result);
																	formik.setFieldValue(
																		`products.[${index}].image`,
																		e.target.files[0],
																	);
																}}
															/>
															<label
																className="custom-file-label overflow-hidden"
																htmlFor="validatedCustomFile">
																{!!formik.values.products?.[index]?.image &&
																formik.values.products?.[index]?.path
																	? formik.values.products?.[index]?.image?.name
																	: "Choose file..."}
															</label>
															{formik.errors?.products?.[index]?.image &&
															formik.touched?.products?.[index]?.image ? (
																<p className="error">{formik.errors?.products?.[index]?.image}</p>
															) : null}
														</div>
													</div>
												</div>
												<div className="col-lg-7">
													<input
														onChange={formik.handleChange}
														name={`products.[${index}].name`}
														value={formik.values.products?.[index]?.name}
														onBlur={formik.handleBlur}
														type="text"
														className="form-control mb-3"
														placeholder="Product Name"
													/>
													{formik.errors?.products?.[index]?.name &&
													formik.touched?.products?.[index]?.name ? (
														<p className="error">{formik.errors?.products?.[index]?.name}</p>
													) : null}
													<textarea
														onChange={formik.handleChange}
														name={`products.[${index}].description`}
														value={formik.values.products?.[index]?.description}
														onBlur={formik.handleBlur}
														required
														rows={5}
														className="form-control"
														placeholder="Write description about metal, plating, gemstone etc.. "
													/>
													{formik.errors?.products?.[index]?.description &&
													formik.touched?.products?.[index]?.description ? (
														<p className="error">{formik.errors?.products?.[index]?.description}</p>
													) : null}
													<div className="row">
														<div className="col-lg-4 offset-lg-8"></div>
													</div>
												</div>
												<div className="col-lg-2">
													<form>
														<div className="form-row text-right">
															<div className="card-body pt-0">
																<button
																	type="button"
																	className="btn btn-primary btn-block"
																	onClick={() => duplicateHandler(product)}>
																	Duplicate
																</button>
															</div>
														</div>
													</form>
												</div>
											</div>
											<hr className="my-4" />
										</div>
									))}
								</div>
								<div style={{ width: "50%", alignSelf: "center" }}>
									<button
										type="button"
										onClick={formik.handleSubmit}
										className="btn btn-info btn-block">
										Save All
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
