//dev
// export const API_URL = "https://ejapi.herokuapp.com/"

//prod
export const API_URL = "http://13.127.106.128/api/";

//Prod

export const S3_CONFIG = {
	bucketName: "ej-storage-prod",
	region: "ap-south-1",
	accessKeyId: "AKIAUKTR6RE4DXGHOLOM",
	secretAccessKey: "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",
};
