import React from "react";

export const ProductStatus = ({ product_status }) => {
	return (
		<ul className="events">
			<li>
				{!product_status || product_status == 0 ? <time2></time2> : <time></time>}
				<span>Designer have Completed the design.</span>
			</li>

			<li>
				{product_status == 1 ? (
					<time2></time2>
				) : product_status == 0 ? (
					<time3></time3>
				) : (
					<time></time>
				)}
				<span1>Sample Design is ready.</span1>
			</li>

			<li>
				{product_status == 2 ? (
					<time2></time2>
				) : product_status == 0 || product_status == 1 ? (
					<time3></time3>
				) : (
					<time></time>
				)}
				<span2>Plating with gold is getting ready.</span2>
			</li>

			<li>
				{product_status == 3 ? (
					<time2></time2>
				) : product_status == 0 || product_status == 1 || product_status == 2 ? (
					<time3></time3>
				) : (
					<time></time>
				)}
				<span3>Price will be added tomorrow. </span3>
			</li>
		</ul>
	);
};
