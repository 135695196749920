import React from "react";
import { Loader } from "../../components/Loader";
import { useOrders } from "../../hooks";
import { useAppSelector } from "../../redux";
import { AuthStore } from "../../redux/auth";
import moment from "moment";
import { Link } from "react-router-dom";

export const Orders = () => {
	const { user } = useAppSelector(AuthStore);
	const isTeam = user?.userData?.userRole == 1;

	const { isOrdersLoading, orders } = useOrders(
		isTeam ? null : user?.userData.id,
		isTeam || !!user?.userData.id,
	);

	return (
		<div>
			<Loader isBusy={isOrdersLoading} />
			<div className="page-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card px-2 py-2">
								<div className="card-body  pt-0">
									<div className="table-responsive">
										<table
											className="table table-centered datatable dt-responsive nowrap "
											style={{
												borderCollapse: "collapse",
												borderSpacing: 0,
												width: "100%",
											}}>
											<thead className="thead-light">
												{isTeam ? (
													<tr>
														<th>Order ID</th>
														<th>Date</th>
														<th>Name</th>
														<th>Email</th>
														<th>Phone Number</th>
														<th>Orders QTY</th>
													</tr>
												) : (
													<tr>
														<th>Order ID</th>
														<th>Date</th>
														<th>Collection Name</th>
														<th>Product Name</th>
														<th>QTY</th>
														<th>Price</th>
														<th>Order Status</th>
														<th>Invoice</th>
													</tr>
												)}
											</thead>
											<tbody>
												{orders.map((order, index) =>
													isTeam ? (
														<tr key={index}>
															<td>
																<a
																	href="javascript: void(0);"
																	className="text-dark font-weight-bold">
																	#NZ{order.id}
																</a>
															</td>
															<td>{moment(order.created_date).format("DD MMM, YYYY")}</td>
															<td>{order.address_name}</td>
															<td>{order.email_address}</td>
															<td>+91 {order.phone_number}</td>
															<td>{order.product_qty}</td>
														</tr>
													) : (
														<tr key={index}>
															<td>
																<a
																	href="javascript: void(0);"
																	className="text-dark font-weight-bold">
																	#NZ{order.id}
																</a>
															</td>
															<td>{moment(order.created_date).format("DD MMM YYYY")}</td>
															<td>{order.collection_name}</td>
															<td>{order.product_name}</td>
															<td>{order.product_qty}</td>
															<td>${order.total_price}</td>
															<td>
																<div className="badge badge-soft-success font-size-12">
																	Delivered
																</div>
															</td>
															<td>
																<Link
																	to={"/ejcustomer/invoice/" + order.id}
																	className="btn btn-light btn-rounded">
																	Invoice <i className="mdi mdi-download ml-2" />
																</Link>
															</td>
														</tr>
													),
												)}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
