import React from "react";
import { Loader } from "../../../components/Loader";
import { useUpdateUser } from "../../../hooks";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { AuthStore, userLoggedin } from "../../../redux/auth";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { getBadRequestErrorMessage } from "../../../api";
import md5 from "md5";

export const AccountSettings = () => {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector(AuthStore);

	const { isUpdating, update } = useUpdateUser(user?.userData?.id);

	const validation = Yup.object().shape({
		userName: Yup.string().required("Required"),
		userEmail: Yup.string().email("Invalid email").required("Required"),
		password: Yup.string().required("Required"),
		companyName: Yup.string(),
		designation: Yup.string(),
	});

	// const cookies = new Cookies();
	// const [Error, setError] = useState("");
	// const [Success, setSuccess] = useState("");
	// const [UserDetails, setUserDetails] = useState({
	// 	userName: "",
	// 	userEmail: "",
	// 	companyName: "",
	// 	designation: "",
	// 	user_img: "",
	// });

	// useEffect(() => {
	// 	if (cookies.get("customer") != undefined) {
	// 		const userdetails = cookies.get("customer").userData;
	// 		console.log("userdetails", userdetails);
	// 		// eslint-disable-next-line quotes, no-useless-escape
	// 		let data = JSON.parse(JSON.stringify(userdetails).replace(/\:"null"/gi, ':""'));
	// 		setUserDetails(data);
	// 	}
	// }, []);

	// const onSaveProfile = async (e) => {
	// 	e.preventDefault();
	// 	let file = UserDetails.user_img;
	// 	let uploadImageResponse = "";
	// 	if (file != "") {
	// 		let newFileName = Date.now() + "." + file["type"].split("/")[1];
	// 		console.log("newFileName", newFileName);
	// 		const uploadImage = await ReactS3Client.uploadFile(file, newFileName)
	// 			.then((data) => {
	// 				return data.location;
	// 			})
	// 			.catch(() => {
	// 				return "";
	// 			});
	// 		uploadImageResponse = await uploadImage;
	// 		console.log("uploadImageResponse", uploadImageResponse);
	// 	}
	// 	const isLoggedInUser = cookies.get("customer");
	// 	fetch(API_URL + "auth/users/" + UserDetails.id, {
	// 		method: "post",
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 			Authorization: "Bearer " + isLoggedInUser.token,
	// 		},
	// 		body: JSON.stringify({
	// 			...UserDetails,
	// 			certificate: uploadImageResponse,
	// 		}),
	// 	})
	// 		.then((response) => response.json())
	// 		.then((res) => {
	// 			console.log("res", res.status);
	// 			if (res.status == 200) {
	// 				let user = { ...isLoggedInUser, userData: { ...UserDetails } };
	// 				cookies.set("customer", user);
	// 				setSuccess(res.response);
	// 			} else {
	// 				setError(res.response);
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.error(error);
	// 		});
	// };

	return (
		<Formik
			initialValues={{
				userName: user?.userData?.userName || "",
				userEmail: user?.userData?.userEmail || "",
				password: "",
				companyName: user?.userData?.companyName || "",
				designation: user?.userData?.designation || "",
			}}
			validationSchema={validation}
			onSubmit={async (values) => {
				try {
					await update({
						userName: values.userName,
						userEmail: values.userEmail,
						password: md5(values.password),
						companyName: values.companyName,
						designation: values.designation,
					});
					dispatch(
						userLoggedin({
							...user,
							userData: {
								...user?.userData,
								userName: values.userName,
								userEmail: values.userEmail,
								password: md5(values.password),
								companyName: values.companyName,
								designation: values.designation,
							},
						}),
					);
					toast.success("Details updated successfully");
				} catch (error) {
					console.log(error);
					toast.error(getBadRequestErrorMessage(error));
				}
			}}
		>
			<div>
				<Loader isBusy={isUpdating} />
				<div className="page-content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<div className="card px-2 py-2">
									<div className="card-body">
										<Form>
											<div className="row">
												<div className="col-lg-6">
													<div>
														<div className="form-group mb-4">
															<label htmlFor="input-date1">Full Name</label>
															<Field name="userName">
																{({ field }) => (
																	<input {...field} className="form-control" type="text" />
																)}
															</Field>
															<ErrorMessage name="userName" component="p" className="error" />
														</div>
														<div className="form-group mb-4">
															<label htmlFor="input-date2">Email</label>
															<Field name="userEmail">
																{({ field }) => (
																	<input
																		{...field}
																		className="form-control"
																		type="email"
																		id="example-email-input"
																	/>
																)}
															</Field>
															<ErrorMessage name="userEmail" component="p" className="error" />
														</div>
														<div className="form-group mb-4">
															<label htmlFor="input-date2">Password</label>
															<Field name="password">
																{({ field }) => (
																	<input {...field} className="form-control" type="password" />
																)}
															</Field>
															<ErrorMessage name="password" component="p" className="error" />
														</div>
														<div className="form-group mb-4">
															<label htmlFor="input-repeat">Company Name (Optional)</label>
															<Field name="companyName">
																{({ field }) => (
																	<input {...field} className="form-control" type="text" />
																)}
															</Field>
															<ErrorMessage name="companyName" component="p" className="error" />
														</div>
														<div className="form-group mb-4">
															<label htmlFor="input-repeat">Designation(Optional)</label>
															<Field name="designation">
																{({ field }) => (
																	<input {...field} className="form-control" type="text" />
																)}
															</Field>
															<ErrorMessage name="designation" component="p" className="error" />
														</div>
													</div>
												</div>
											</div>
											<button type="submit" className="btn btn-primary waves-effect waves-light">
												Save
											</button>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Formik>
	);
};
