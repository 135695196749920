import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import md5 from "md5";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useLogin } from "../../hooks";
import { localStorageCustomerInfo, localStorageTeamInfo } from "../../Utils/helper";
import { useAppDispatch, useAppSelector } from "../../redux";
import { AuthStore, userLoggedin } from "../../redux/auth";
import { Loader } from "../../components/Loader";
import { toast } from "react-toastify";
import { getBadRequestErrorMessage } from "../../api";

export const Login = () => {
	const location = useLocation();
	const navigation = useNavigate();
	const dispatch = useAppDispatch();
	const { isAuthenticated, user } = useAppSelector(AuthStore);
	const isTeam = location.pathname?.includes("ejteam");

	const { login, isLogging } = useLogin();

	useEffect(() => {
		if (isAuthenticated) {
			if (user.userData.userRole == 2) {
				if (user.userData.email_verifyed == 1) navigation("/ejcustomer/updates");
				else navigation("/ejcustomer/emailverification");
			}
			if (user.userData.userRole == 1) {
				navigation("/ejteam/updates");
			}
		}
	}, [isAuthenticated]);

	const validation = Yup.object().shape({
		email: Yup.string().email("Invalid email").required("Required"),
		password: Yup.string().required("Required"),
	});

	return (
		<Formik
			initialValues={{
				email: "",
				password: "",
			}}
			validationSchema={validation}
			onSubmit={async (values) => {
				try {
					const response = await login({
						username: values.email,
						password: md5(values.password),
					});
					if (isTeam) {
						if (response.userData.userRole == 2) {
							toast.error("Invalid email or password");
						} else if (response.userData.userRole == 1) {
							localStorage.setItem(localStorageTeamInfo, JSON.stringify(response));
							dispatch(userLoggedin(response));
						} else {
							toast.error("Unknown error occured, please try again.");
						}
					} else {
						if (response.userData.userRole == 2) {
							localStorage.setItem(localStorageCustomerInfo, JSON.stringify(response));
							dispatch(userLoggedin(response));
						} else if (response.userData.userRole == 1) {
							toast.error("Invalid email or password");
						} else {
							toast.error("Unknown error occured, please try again.");
						}
					}
				} catch (error) {
					console.log(error);
					toast.error(getBadRequestErrorMessage(error));
					throw new Error(error);
				}
			}}>
			<div>
				<Loader isBusy={isLogging} />
				<div className="home-btn d-none d-sm-block">
					<Link to="/ejcustomer/login">
						<i className="mdi mdi-home-variant h2 text-white" />
					</Link>
				</div>
				<div style={{ backgroundColor: "#ffffff" }}>
					<div className="container-fluid p-0">
						<div className="row no-gutters">
							<div className="col-lg-4">
								<div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
									<div className="w-100">
										<div className="row justify-content-center">
											<div className="col-lg-9">
												<div>
													<div className="text-center">
														<div>
															<Link to="/ejcustomer/login" className="logo">
																<img src="assets/images/logo-dark.png" height={30} alt="logo" />
															</Link>
														</div>
														<h4 className="font-size-18 mt-3">Welcome Back !</h4>
														<p className="text-muted">Sign in to continue to Essentials Jewelry.</p>
													</div>
													<div className="p-2 mt-4">
														<Form className="form-horizontal">
															<div className="form-group auth-form-group-custom mb-3">
																<i className="ri-user-2-line auti-custom-input-icon" />
																<label htmlFor="username">Enter Email</label>
																<Field name="email">
																	{({ field }) => (
																		<input
																			{...field}
																			id="username"
																			type="text"
																			placeholder="Enter Email"
																			className="form-control"
																		/>
																	)}
																</Field>
																<ErrorMessage name="email" component="p" className="error" />
															</div>
															<div className="form-group auth-form-group-custom mb-3">
																<i className="ri-lock-2-line auti-custom-input-icon" />
																<label htmlFor="userpassword">Password</label>
																<Field name="password">
																	{({ field }) => (
																		<input
																			{...field}
																			type="password"
																			className="form-control"
																			id="userpassword"
																			placeholder="Enter password"
																		/>
																	)}
																</Field>
																<ErrorMessage name="password" component="p" className="error" />
															</div>
															<div className="custom-control custom-checkbox">
																<input
																	type="checkbox"
																	className="custom-control-input"
																	id="customControlInline"
																/>
																<label
																	className="custom-control-label"
																	htmlFor="customControlInline">
																	Remember me
																</label>
															</div>
															<div className="mt-3 text-center">
																<button
																	className="btn btn-primary w-md waves-effect waves-light"
																	type="submit">
																	Log In
																</button>
															</div>
															{!isTeam && (
																<div className="mt-3 text-center">
																	<Link to="/ejcustomer/forgotpassword" className="text-muted">
																		<i className="mdi mdi-lock mr-1" /> Forgot your password?
																	</Link>
																</div>
															)}
														</Form>
													</div>
													<div className="mt-3 text-center">
														{!isTeam && (
															<p>
																Don&apos;t have an account ?
																<Link
																	to="/ejcustomer/signup"
																	className="font-weight-medium text-primary">
																	<button
																		className="btn btn-primary w-md waves-effect waves-light"
																		type="submit">
																		Register
																	</button>
																</Link>
															</p>
														)}
														<p>© 2021 Essentials Jewelry.</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-lg-8">
								<div className="authentication-bg"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Formik>
	);
};
