import { api } from "../index";

export const getCart = () =>
	api
		.get("cart")
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const getProducts = () =>
	api
		.get("products/available")
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const addToCart = (data) =>
	api
		.post("cart", JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const removeFromCart = (product_id) =>
	api
		.delete(`cart/${product_id}`)
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});

export const placeOrder = (data) =>
	api
		.post("orders", JSON.stringify(data))
		.then((response) => response)
		.catch((error) => {
			throw new Error(error);
		});
