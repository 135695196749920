import React from "react";
import { Loader } from "../../../components/Loader";
import { useDashboard } from "../../../hooks";

export const Dashboard = () => {
	const { dashboardInfo, isLoading } = useDashboard();
	console.log(dashboardInfo);

	const isBusy = isLoading;

	return (
		<div>
			<Loader isBusy={isBusy} />
			{dashboardInfo != null && (
				<div className="page-content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xl-8">
								<div className="row">
									<div className="col-md-4">
										<div className="card">
											<div className="card-body">
												<div className="media">
													<div className="media-body overflow-hidden">
														<p className="text-truncate font-size-14 mb-2">Number Of Customers</p>
														<h4 className="mb-0">{dashboardInfo.customers}</h4>
													</div>
													<div className="text-primary">
														<i className="ri-stack-line font-size-24" />
													</div>
												</div>
											</div>
											<div className="card-body border-top py-3">
												{/* <div className="text-truncate">
													<span className="badge badge-soft-success font-size-11">
														<i className="mdi mdi-menu-up"> </i> 2.4%{" "}
													</span>
													<span className="text-muted ml-2">From previous period</span>
												</div> */}
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="card">
											<div className="card-body">
												<div className="media">
													<div className="media-body overflow-hidden">
														<p className="text-truncate font-size-14 mb-2">Number Of Orders</p>
														<h4 className="mb-0">{dashboardInfo.orders}</h4>
													</div>
													<div className="text-primary">
														<i className="ri-store-2-line font-size-24" />
													</div>
												</div>
											</div>
											<div className="card-body border-top py-3">
												<div className="text-truncate">
													{/* <span className="badge badge-soft-success font-size-11"><i className="mdi mdi-menu-up"> </i> 2.4% </span>
                                                        <span className="text-muted ml-2">From previous period</span> */}
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-4">
										<div className="card">
											<div className="card-body">
												<div className="media">
													<div className="media-body overflow-hidden">
														<p className="text-truncate font-size-14 mb-2">Total Sales</p>
														<h4 className="mb-0">$ {dashboardInfo.total_value}</h4>
													</div>
													<div className="text-primary">
														<i className="ri-briefcase-4-line font-size-24" />
													</div>
												</div>
											</div>
											<div className="card-body border-top py-3">
												{/* <div className="text-truncate">
                                                        <span className="badge badge-soft-success font-size-11"><i className="mdi mdi-menu-up"> </i> 2.4% </span>
                                                        <span className="text-muted ml-2">From previous period</span>
                                                    </div> */}
											</div>
										</div>
									</div>
								</div>
								{/* end row */}
								{/* <div className="card">
                                        <div className="card-body">
                                            <div className="float-right d-none d-md-inline-block">
                                                <div className="btn-group mb-2">
                                                    <div className="input-daterange input-group mr-2" data-provide="datepicker" data-date-format="dd M, yyyy" data-date-autoclose="true">
                                                        <input type="text" className="form-control" name="start" />
                                                        <input type="text" className="form-control" name="end" />
                                                        <div className="input-group-append">
                                                            <span className="input-group-text"><i className="mdi mdi-calendar" /></span>
                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn btn-sm btn-light">Today</button>
                                                    <button type="button" className="btn btn-sm btn-light active">Weekly</button>
                                                    <button type="button" className="btn btn-sm btn-light">Monthly</button>
                                                </div>
                                            </div>
                                            <h4 className="card-title mt-2">Revenue</h4>
                                            <div>
                                                <div id="line-column-chart" className="apex-charts" dir="ltr" />
                                            </div>
                                        </div>
                                        <div className="card-body border-top text-center">
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="d-inline-flex">
                                                        <h5 className="mr-2">$12,253</h5>
                                                        <div className="text-success">
                                                            <i className="mdi mdi-menu-up font-size-14"> </i>2.2 %
                                                        </div>
                                                    </div>
                                                    <p className="text-muted text-truncate mb-0">This month</p>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="mt-4 mt-sm-0">
                                                        <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-primary font-size-10 mr-1" /> This Year :</p>
                                                        <div className="d-inline-flex">
                                                            <h5 className="mb-0 mr-2">$ 34,254</h5>
                                                            <div className="text-success">
                                                                <i className="mdi mdi-menu-up font-size-14"> </i>2.1 %
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-4">
                                                    <div className="mt-4 mt-sm-0">
                                                        <p className="mb-2 text-muted text-truncate"><i className="mdi mdi-circle text-success font-size-10 mr-1" /> Previous Year :</p>
                                                        <div className="d-inline-flex">
                                                            <h5 className="mb-0">$ 32,695</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
							</div>
							<div className="col-xl-4">
								{/* <div className="card">
                                        <div className="card-body">
                                            <div className="float-right">
                                                <select className="custom-select custom-select-sm">
                                                    <option selected>Apr</option>
                                                    <option value={1}>Mar</option>
                                                    <option value={2}>Feb</option>
                                                    <option value={3}>Jan</option>
                                                </select>
                                            </div>
                                            <h4 className="card-title mb-4">Total Oders</h4>
                                            <div id="donut-chart" className="apex-charts" />
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="text-center mt-4">
                                                        <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-primary font-size-10 mr-1" /> Product A</p>
                                                        <h5>42 %</h5>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-center mt-4">
                                                        <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-success font-size-10 mr-1" /> Product B</p>
                                                        <h5>26 %</h5>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-center mt-4">
                                                        <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-warning font-size-10 mr-1" /> Product C</p>
                                                        <h5>42 %</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <div className="text-center mt-4">
                                                        <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-info font-size-10 mr-1" /> Product D</p>
                                                        <h5>42 %</h5>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-center mt-4">
                                                        <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-pink font-size-10 mr-1" /> Product E</p>
                                                        <h5>26 %</h5>
                                                    </div>
                                                </div>
                                                <div className="col-4">
                                                    <div className="text-center mt-4">
                                                        <p className="mb-2 text-truncate"><i className="mdi mdi-circle text-body font-size-10 mr-1" /> Product F</p>
                                                        <h5>42 %</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
							</div>
						</div>
						{/* end row */}
					</div>
				</div>
			)}
		</div>
	);
};
