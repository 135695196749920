import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../redux";
import { AuthStore, userLoggedout } from "../redux/auth";
import { outsideClickedHandler } from "../Utils/helper";

export const Header = () => {
	const dispatch = useAppDispatch();
	const { user } = useAppSelector(AuthStore);
	const [showDropDown, setShowDropDown] = useState(false);
	const isTeam = user?.userData?.userRole == 1;

	const wrapperRef = useRef("menu");
	outsideClickedHandler(wrapperRef, () => {
		setShowDropDown(false);
	});

	const handleLogout = () => {
		localStorage.clear();
		dispatch(userLoggedout());
	};

	return (
		<header id="page-topbar">
			<div className="navbar-header">
				<div className="d-flex">
					<div className="navbar-brand-box">
						<Link className="logo logo-dark" to={`/${isTeam ? "ejteam" : "ejcustomer"}/updates`}>
							<span className="logo-sm">
								<img src="assets/images/logo-dark.png" alt="" height="30" />
							</span>
							<span className="logo-lg">
								<img src="assets/images/logo-dark.png" alt="" height="30" />
							</span>
						</Link>
						<Link className="logo logo-light" to={`/${isTeam ? "ejteam" : "ejcustomer"}/updates`}>
							<span className="logo-sm">
								<img src="assets/images/logo-dark.png" alt="" height="30" />
							</span>
							<span className="logo-lg">
								<img src="assets/images/logo-dark.png" alt="" height="30" />
							</span>
						</Link>
					</div>
					<button
						type="button"
						className="btn btn-sm px-3 font-size-24 d-lg-none header-item"
						data-toggle="collapse"
						data-target="#topnav-menu-content">
						<i className="ri-menu-2-line align-middle"></i>
					</button>
				</div>
				<div className="d-flex">
					<div className="topbar-text d-inline-block">
						<p className=" text-center pt-2">
							FOR ANY ASSISTANCE PLEASE CONTACT +91 931 4494 017 / sales@essentials-jewelry.com
						</p>
					</div>
				</div>
				<div className="d-flex">
					{!isTeam && (
						<div className="dropdown d-inline-block">
							<Link to={`/${isTeam ? "ejteam" : "ejcustomer"}/cart`}>
								<button type="button" className="btn header-item noti-icon waves-effect">
									<i className="ri-shopping-cart-2-line"></i>
									<span className="noti-dot"></span>
								</button>
							</Link>
						</div>
					)}
					<div className="dropdown d-inline-block user-dropdown" ref={wrapperRef}>
						<button
							type="button"
							className="btn header-item waves-effect"
							id="page-header-user-dropdown"
							data-toggle="dropdown"
							aria-haspopup="true"
							aria-expanded="false"
							onClick={() => setShowDropDown((prevData) => !prevData)}>
							<img
								className="rounded-circle header-profile-user"
								src="assets/images/users/avatar-2.jpg"
								alt="Header Avatar"
							/>
							<span className="d-none d-xl-inline-block ml-1">
								{isTeam ? "EJ Team" : "EJ Customer"}
							</span>
							<i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
						</button>
						{showDropDown ? (
							<div
								className="dropdown-menu dropdown-menu-right show"
								x-placement="bottom-end"
								aria-labelledby="page-header-user-dropdown">
								{!isTeam && (
									<Link
										className="dropdown-item"
										to={`/${isTeam ? "ejteam" : "ejcustomer"}/accountsettings`}
										onClick={() => setShowDropDown(false)}>
										<i className="ri-user-line align-middle mr-1" /> Profile
									</Link>
								)}
								<div className="dropdown-divider" />
								<a className="dropdown-item text-danger" onClick={handleLogout}>
									<i className="ri-shut-down-line align-middle mr-1 text-danger" /> Logout
								</a>
							</div>
						) : null}
					</div>
				</div>
			</div>
		</header>
	);
};
