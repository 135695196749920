import React from "react";
import { Link } from "react-router-dom";
import { useTeamMembers } from "../../../hooks";
import { Loader } from "../../../components/Loader";

export default function Team() {
	const { isLoading, teamMembers } = useTeamMembers();

	const isBusy = isLoading;

	return (
		<div>
			<Loader isBusy={isBusy} />
			<div className="page-content">
				<div className="container-fluid">
					<div className="row">
						<div className="col-lg-12">
							<div className="card px-2 py-2">
								<div className="card-body">
									<div>
										<Link className="btn btn-primary mb-2" to="/ejteam/addteammember">
											<i className="mdi mdi-plus mr-2" /> Add Team Member
										</Link>
									</div>
									<div className="table-responsive mt-3">
										<table
											className="table table-centered datatable dt-responsive nowrap "
											style={{
												borderCollapse: "collapse",
												borderSpacing: 0,
												width: "100%",
											}}>
											<thead className="thead-light">
												<tr>
													<th>Name</th>
													<th>Email</th>
													<th>Phone</th>
													<th>Role</th>
													<th style={{ width: 120 }}>Action</th>
												</tr>
											</thead>
											<tbody>
												{teamMembers?.map((member, index) => (
													<tr key={index}>
														<td>{member.userName}</td>
														<td>{member.userEmail}</td>
														<td>{member.MobileNumber}</td>
														<td>{member.designation}</td>
														<td>
															<Link
																to={`/ejteam/editteammember/${member.id}`}
																className="mr-3 text-primary"
																data-toggle="tooltip"
																data-placement="top"
																title
																data-original-title="Edit">
																<i className="mdi mdi-pencil font-size-18" />
															</Link>
															<a
																href="javascript:void(0);"
																className="text-danger"
																data-toggle="tooltip"
																data-placement="top"
																title
																data-original-title="Delete">
																<i className="mdi mdi-trash-can font-size-18" />
															</a>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
