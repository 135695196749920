import md5 from "md5";
import React from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useResetPassword } from "../../hooks";
import { Loader } from "../../components/Loader";
import { toast } from "react-toastify";
import { getBadRequestErrorMessage } from "../../api";
import { useNavigate } from "react-router";

export const ResetPassword = () => {
	const { isResetting, resetPassword } = useResetPassword();
	const navigation = useNavigate();

	const validation = Yup.object().shape({
		password: Yup.string().required("Required"),
		confirmPassword: Yup.string()
			.required("Required")
			.oneOf([Yup.ref("password"), null], "Passwords must match"),
	});

	return (
		<Formik
			initialValues={{
				password: "",
				confirmPassword: "",
			}}
			validationSchema={validation}
			onSubmit={async (values) => {
				console.log(values);
				try {
					await resetPassword({
						password: md5(values.password),
					});
					navigation("/ejcustomer/login");
				} catch (error) {
					console.log(error);
					toast.error(getBadRequestErrorMessage(error));
				}
			}}>
			<div>
				<Loader isBusy={isResetting} />
				<div className="container-fluid p-0">
					<div className="row no-gutters">
						<div className="col-lg-4">
							<div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
								<div className="w-100">
									<div className="row justify-content-center">
										<div className="col-lg-9">
											<div>
												<div className="text-center">
													<div>
														<a href="/" className="logo">
															<img src="assets/images/logo-dark.png" height={30} alt="logo" />
														</a>
													</div>
													<h4 className="font-size-18 mt-4">Reset Password</h4>
													<p className="text-muted">Reset your password to Essentials Jewelry.</p>
												</div>
												<div className="p-2 mt-5">
													<Form className="form-horizontal">
														<div className="form-group auth-form-group-custom mb-2">
															<i className="ri-lock-2-line auti-custom-input-icon" />
															<label htmlFor="userpassword">Password</label>
															<Field name="password">
																{({ field }) => (
																	<input
																		type="password"
																		className="form-control"
																		placeholder="Enter password"
																		{...field}
																	/>
																)}
															</Field>
															<ErrorMessage name="password" component="p" className="error" />
														</div>
														<div className="form-group auth-form-group-custom mb-2">
															<i className="ri-lock-2-line auti-custom-input-icon" />
															<label htmlFor="userpassword">Confirm Password</label>
															<Field name="confirmPassword">
																{({ field }) => (
																	<input
																		type="password"
																		className="form-control"
																		placeholder="Confirm password"
																		{...field}
																	/>
																)}
															</Field>
															<ErrorMessage
																name="confirmPassword"
																component="p"
																className="error"
															/>
														</div>
														<div className="mt-4 text-center">
															<button
																className="btn btn-primary w-md waves-effect waves-light"
																type="submit">
																Reset
															</button>
														</div>
													</Form>
												</div>
												<div className="mt-5 text-center">
													<p>© 2021 Essentials Jewelry.</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-lg-8">
							<div className="authentication-bg">{/* <div className="bg-overlay"></div> */}</div>
						</div>
					</div>
				</div>
			</div>
		</Formik>
	);
};
