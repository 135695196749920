import React from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useUserById, useUpdateUser } from "../../../hooks";
import { Loader } from "../../../components/Loader";
import { toast } from "react-toastify";
import { getBadRequestErrorMessage } from "../../../api";

export const EditCustomer = () => {
	const { id } = useParams();
	const navigation = useNavigate();
	const { userInfo, isLoading } = useUserById(id);
	const { isUpdating, update } = useUpdateUser(id);

	const validation = Yup.object().shape({
		userName: Yup.string().required("Required"),
		userEmail: Yup.string().email("Invalid email").required("Required"),
		companyName: Yup.string(),
		designation: Yup.string(),
		joiningDate: Yup.string(),
		address: Yup.string(),
		MobileNumber: Yup.number().typeError("Number only"),
	});

	if (isLoading) return <Loader isBusy={isLoading} />;

	return (
		<Formik
			initialValues={{
				id,
				isActive: 1,
				userRole: 2,
				certificate: null,
				userName: userInfo?.userName || "",
				userEmail: userInfo?.userEmail || "",
				companyName: userInfo?.companyName || "",
				designation: userInfo?.designation || "",
				joiningDate:
					userInfo?.joiningDate && userInfo?.joiningDate == "null"
						? ""
						: userInfo?.joiningDate || "",
				address: userInfo?.address || "",
				MobileNumber: typeof userInfo?.MobileNumber == "number" ? userInfo?.MobileNumber : "" || "",
			}}
			validationSchema={validation}
			onSubmit={async (values) => {
				try {
					await update(values);
					toast.success("Updated Successfully");
					navigation(-1);
				} catch (error) {
					toast.error(getBadRequestErrorMessage(error));
				}
			}}>
			<div>
				<Loader isBusy={isUpdating} />
				<div className="page-content">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-12">
								<div className="card px-2 py-2">
									<div className="card-body">
										<Form>
											<div className="row">
												<div className="col-lg-6">
													<div>
														<div className="form-group mb-4">
															<label htmlFor="input-date1">Name</label>
															<Field name="userName">
																{({ field }) => (
																	<input
																		{...field}
																		className="form-control"
																		type="text"
																		defaultValue={userInfo?.userName}
																		id="example-text-input"
																	/>
																)}
															</Field>
															<ErrorMessage name="userName" component="p" className="error" />
														</div>
														<div className="form-group mb-4">
															<label htmlFor="input-date2">Phones Number</label>
															<Field name="MobileNumber">
																{({ field }) => (
																	<input
																		{...field}
																		className="form-control"
																		defaultValue={userInfo?.MobileNumber}
																		type="number"
																		id="tele"
																	/>
																)}
															</Field>
															<ErrorMessage name="MobileNumber" component="p" className="error" />
														</div>
														<div className="form-group mb-4">
															<label htmlFor="input-datetime">Address</label>
															<Field name="address">
																{({ field }) => (
																	<textarea
																		{...field}
																		rows={5}
																		defaultValue={userInfo?.address}
																		className="form-control"
																		spellCheck="false"
																	/>
																)}
															</Field>
															<ErrorMessage name="address" component="p" className="error" />
														</div>
													</div>
												</div>
												<div className="col-lg-6">
													<div className="mt-4 mt-lg-0">
														<div className="form-group mb-4">
															<label htmlFor="input-date2">Email</label>
															<Field name="userEmail">
																{({ field }) => (
																	<input
																		{...field}
																		className="form-control"
																		defaultValue={userInfo?.userEmail}
																		type="email"
																		id="example-email-input"
																	/>
																)}
															</Field>
															<ErrorMessage name="userEmail" component="p" className="error" />
														</div>
														<div className="form-group mb-4">
															<label htmlFor="input-date2">Joining Date</label>
															<Field name="joiningDate">
																{({ field }) => (
																	<input
																		{...field}
																		id="date"
																		type="date"
																		className="form-control"
																	/>
																)}
															</Field>
															<ErrorMessage name="joiningDate" component="p" className="error" />
														</div>
														<div className="form-group mb-4">
															<label htmlFor="input-ip">Company Name</label>
															<Field name="companyName">
																{({ field }) => (
																	<input
																		{...field}
																		className="form-control"
																		defaultValue={userInfo?.companyName}
																		type="text"
																		id="example-text-input"
																	/>
																)}
															</Field>
															<ErrorMessage name="companyName" component="p" className="error" />
														</div>
														<div className="form-group mb-4">
															<label htmlFor="input-date2">Designation</label>
															<Field name="designation">
																{({ field }) => (
																	<input
																		{...field}
																		className="form-control"
																		defaultValue={userInfo?.designation}
																		type="text"
																		id="example-text-input"
																	/>
																)}
															</Field>
															<ErrorMessage name="designation" component="p" className="error" />
														</div>
													</div>
												</div>
											</div>
											<button type="submit" className="btn btn-primary waves-effect waves-light">
												Save
											</button>
											<button
												type="button"
												className="btn btn-secondary waves-effect waves-light"
												onClick={() => navigation(-1)}>
												Cancel
											</button>
										</Form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Formik>
	);
};
